import { APP_INITIALIZER, ErrorHandler, NgModule, Injectable } from "@angular/core";
import { SentryService } from "./sentry.service";
import { environment } from "@emc-environment/environment";
import * as Sentry from "@sentry/browser";
import { HttpErrorResponse } from "@angular/common/http";


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === "string" || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(err: any) {
    const extractedError = this.extractError(err) || "Handled unknown error";
    console.error(extractedError);
    if (environment.production) {
      Sentry.captureException(extractedError);
    }
  }
}

export const setupSentry = () => {
  if (environment.production) {
    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.appName,
      integrations: [
        new Sentry.Integrations.TryCatch({
          XMLHttpRequest: false,
        }),
      ],
    });

  }

  return () => {
  };
};

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    SentryService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupSentry,
      multi: true
    }
  ]
})
export class SentryModule {
}
