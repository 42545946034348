import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { CommonUtils } from "@emc-utils/common-utils";

@Directive({selector: "[aeSizeClass]"})
export class SizeClassDirective {
  private currentClass: string;

  constructor(private renderer: Renderer2, private host: ElementRef) {
    this.configureSizeClass();
  }

  @HostListener("window:resize")
  configureSizeClass() {
    const sizeClass = CommonUtils.getSizeClass();

    if (this.currentClass !== sizeClass) {
      this.setSizeClass(sizeClass);
    }
  }

  setSizeClass(className: string) {
    const el = this.host.nativeElement;
    this.renderer.removeClass(el, CommonUtils.XL);
    this.renderer.removeClass(el, CommonUtils.LG);
    this.renderer.removeClass(el, CommonUtils.MD);
    this.renderer.removeClass(el, CommonUtils.SM);
    this.renderer.removeClass(el, CommonUtils.
      XS);
    this.renderer.addClass(el, className);
  }
}
