<div [formGroup]="group" class="emc-box">
  <div class="head">
    <span class="space-filler"></span>
    <button (click)="onDelete()" mat-icon-button>
      <mat-icon color="warn" matTooltip="Delete">delete</mat-icon>
    </button>
  </div>
  <div class="elements">
    <p class="label">Question</p>
    <mat-form-field floatLabel="never">
      <input formControlName="title" matInput placeholder="Title" type="text">
    </mat-form-field>
  </div>
  <ng-container *ngIf="templateId !== 63">
  <div class="elements">
    <p class="label">Options</p>
    <tag-input [modelAsStrings]=true formControlName="options"></tag-input>
  </div>
  </ng-container>
</div>
