import {Component, Inject} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ae-view-container",
  templateUrl: "./view-container.component.html",
  styleUrls: ["./view-container.component.scss"]
})

export class ViewContainerComponent {

  templateId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ViewContainerComponent>
  ) {
    this.templateId = data.templateId;
  }

  close() {
    this.dialogRef.close();
  }
}
