import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { UserFull } from "@emc-models/entities/user.model";

export interface AppState {
  user: UserFull;
  isLoading: boolean;
  isLoaded: boolean;
  returnUrl: string;
  bootstrapped: boolean;
}

export function createInitialState(): AppState {
  return {
    user: null,
    isLoading: false,
    isLoaded: false,
    returnUrl: "dashboard/home",
    bootstrapped: false
  };
}

@Injectable({providedIn: "root"})
@StoreConfig({name: "app"})
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}

