import { HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { NotFoundComponent } from "@emc-modules/core/components/not-found/not-found.component";
import { SizeClassDirective } from "@emc-modules/core/directives/size-class.directive";
import { AnonymousGuard } from "@emc-modules/core/guards/anonymous.guard";
import { AuthGuard } from "@emc-modules/core/guards/auth.guard";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { AlertService } from "@emc-modules/core/services/alert.service";
import { ApiService } from "@emc-modules/core/services/api.service";
import { AppService } from "@emc-modules/core/services/app.service";
import { AuthService } from "@emc-modules/core/services/auth.service";
import { BroadcasterService } from "@emc-modules/core/services/broadcaster.service";
import { ErrorService } from "@emc-modules/core/services/error.service";
import { UploadService } from "@emc-modules/core/services/upload.service";
import { ToastrModule } from "ngx-toastr";
import { WindowresizeService } from "@emc-modules/core/services/windowresize.service";
import { NG_ENTITY_SERVICE_CONFIG } from "@datorama/akita-ng-entity-service";
import { environment } from "@emc-environment/environment";
import { BootstrapGuard } from "@emc-modules/core/guards/bootstrap.guard";
import { OptionsService } from "@emc-services/options.service";
import { PermissionGuard } from "@emc-modules/core/guards/permission.guard";
import { NgxFileDropModule } from "ngx-file-drop";
import { SentryModule } from "@emc-modules/sentry/sentry.module";
import { DbResolver } from "@emc-modules/core/resolvers/db.resolver";
import { DbService } from "@emc-modules/shared/services/db.service";

const SERVICES = [
  AlertService,
  ErrorService,
  BroadcasterService,
  UploadService,
  AppService,
  ApiService,
  AuthService,
  OptionsService,
  WindowresizeService,
  DbService,
  {
    provide: NG_ENTITY_SERVICE_CONFIG,
    useValue: {
      baseUrl: environment.baseUrl.substring(0, environment.baseUrl.length - 1)
    }
  }
];

const COMPONENTS = [
  NotFoundComponent
];

const DIRECTIVES = [
  SizeClassDirective,
];

const RESOLVERS = [DbResolver];

const MANAGERS = [
  AuthManager
];

const GUARDS = [
  AuthGuard,
  AnonymousGuard,
  BootstrapGuard,
  PermissionGuard
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  imports: [
    HttpClientModule,
    NgxFileDropModule,
    SentryModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  providers: [
    ...SERVICES,
    ...GUARDS,
    ...MANAGERS,
    ...RESOLVERS
  ],
})
export class CoreModule {

  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    if (parentModule) {
      console.error("CoreModule is already loaded. Import only in AppModule");
    }
  }
}
