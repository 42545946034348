import {Component, Inject} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ae-mailing-list-container",
  templateUrl: "./mailing-list-container.component.html",
  styleUrls: ["./mailing-list-container.component.scss"]
})

export class MailingListContainerComponent {

  mailingListId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MailingListContainerComponent>
  ) {
this.mailingListId = data.mailingListId;
  }

  close() {
    this.dialogRef.close();
  }
}
