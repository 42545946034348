import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";

@Component({
  selector: "ae-autocomplete",
  templateUrl: "emc-autocomplete.component.html",
  styleUrls: ["./emc-autocomplete.component.scss"]
})

export class EmcAutocompleteComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() control: FormControl = new FormControl();
  @Input() placeholder: string;
  @Input() message: string;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  filteredOptions: ISelectOption[] = [];
  private _options: ISelectOption[] = [];

  get options(): ISelectOption[] {
    return this._options;
  }

  @Input() set options(options: ISelectOption[]) {
    this._options = options;
    this.filteredOptions = options;
  }

  ngOnInit() {
    this.control.valueChanges
      .subscribe((value: string) => {
        if (value) {
          this.filteredOptions = this.options
            .filter(option => option.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
          this.filteredOptions = this.options;
        }
      });
  }

  reset() {
    if (!this.disabled) {
      this.control.reset();
    }
  }

}
