import {Injectable} from "@angular/core";
import {Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {
  InspectionCategoryState,
  InspectionCategoryStore
} from "@emc-state/tools/inspection-category/inspection-category.store";

@Injectable({
  providedIn: "root"
})
@QueryConfig({
  sortBy: "title",
  sortByOrder: Order.ASC
})
export class InspectionCategoryQuery extends QueryEntity<InspectionCategoryState> {

  isLoading$ = this.select("loading");
  isLoaded$ = this.select("loaded");

  constructor(protected store: InspectionCategoryStore) {
    super(store);
  }

}


