<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="popup-title">{{title}}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close(false)">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content">
    <p>{{description}}</p>
    <span class="spacer"></span>
    <div class="buttons">
      <button (click)="save()" color="primary" mat-flat-button>Save</button>
      <button (click)="clickYes()" *ngIf="showYesButton" color="primary" mat-flat-button>{{yesButtonTitle}}</button>
      <button (click)="clickNo()" *ngIf="showNoButton" color="warn" mat-flat-button>{{noButtonTitle}}</button>
    </div>
  </div>
</div>
