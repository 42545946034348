import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { PermissionsState, PermissionsStore } from "./permissions.store";
import { Permission } from "@emc-models/entities/permission.model";

@Injectable({
  providedIn: "root"
})
export class PermissionsQuery extends QueryEntity<PermissionsState, Permission> {
  selectLoading$ = this.select("loading");
  selectLoaded$ = this.select("loaded");

  constructor(protected store: PermissionsStore) {
    super(store);
  }
}
