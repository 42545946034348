<ae-main-container>
  <ng-template mainContainerBody>
    <div *ngIf="!isLoading" class="container">
      <div  class="header">
        <h1 *ngIf="!!state" class="page-title">View {{state?.title}} Template</h1>
        <h1 *ngIf="!state" class="page-title">View {{template?.title}} Template</h1>
        <mat-icon *ngIf="isDialog" color="warn" (click)="OnClick()">clear</mat-icon>
        <button (click)="edit()" *ngIf="PermissionUtils.hasPermission(PermissionType.TOOL_TEMPLATE_EDIT) && !isDialog"
                class="primary"
                mat-button>Edit
        </button>
      </div>
      <ae-breadcrumb *ngIf="!isDialog" [breadcrumbs]="breadcrumbs"></ae-breadcrumb>
      <ng-container *ngFor="let questionSuggestion of template.question_suggestions">
        <div class="emc-box view-template">
          <div class="elements">
            <p class="label">Question</p>
            <p class="content">{{questionSuggestion.title}}</p>
          </div>
          <div class="elements">
            <p class="label">Category</p>
            <p class="content">{{questionSuggestion.category?.title}}</p>
          </div>
          <!-- state-template-checkbox is defined in global. Hack to show checkbox in primary color -->
          <div class="state-template-checkbox checkbox">
            <mat-checkbox [checked]="questionSuggestion.config.is_active" disabled>
              <span class="checkbox-text">Active</span>
            </mat-checkbox>
            <mat-checkbox [checked]="questionSuggestion.config?.has_sub_questions" disabled>
              <span class="checkbox-text">Sub Questions</span>
            </mat-checkbox>
            <mat-checkbox [checked]="questionSuggestion.config?.show_assigned_to" disabled>
              <span class="checkbox-text">Show Assigned To</span>
            </mat-checkbox>
            <mat-checkbox disabled [checked]="questionSuggestion?.config?.required_root_response">
              <span class="checkbox-text">Requires Root Question Response</span>
            </mat-checkbox>
            <ng-container *ngIf="template.type === QuestionType.PULTE">
              <mat-checkbox disabled [checked]="questionSuggestion?.config?.blank_question">
                <span class="checkbox-text">Blank Question</span>
              </mat-checkbox>
            </ng-container>
          </div>

          <div class="elements">
            <p class="label">Default Option</p>
            <p class="info">{{questionSuggestion?.config?.default_option}}</p>
          </div>

          <div *ngIf="questionSuggestion.config?.options" class="elements">
            <p class="label">Options</p>
            <p class="info">
              <mat-chip-list>
                <mat-chip *ngFor="let option of questionSuggestion.config.options">{{option.title}}</mat-chip>
              </mat-chip-list>
            </p>
          </div>

          <div *ngIf="questionSuggestion.config?.has_sub_questions" class="sub-question-container">
            <div *ngFor="let question of questionSuggestion.config.sub_questions" class="sub-question">
              <div class="elements">
                <p class="label">Question</p>
                <p class="content">{{question.title}}</p>
              </div>
              <div class="elements">
                <div class="label">Options</div>
                <div class="content">
                  <mat-chip-list>
                    <ng-container *ngIf="!!question?.options?.length">
                      <ng-container *ngFor="let option of question.options">
                        <mat-chip *ngIf="!!option.trim().length">{{option}}</mat-chip>
                        <ng-container *ngIf="!option.trim().length">-</ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!question?.options?.length">-</ng-container>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button (click)="togglePanel(questionSuggestion.id)" *ngIf="isPanelOpen[questionSuggestion.id]"
                    color="accent"
                    mat-button>
              Hide Responsive Actions
            </button>
            <button (click)="togglePanel(questionSuggestion.id)" *ngIf="!isPanelOpen[questionSuggestion.id]"
                    color="accent"
                    mat-button>Show Responsive Actions
            </button>
          </div>

          <div [@slideInOut]='isPanelOpen[questionSuggestion.id] ? "expanded" : "collapsed"'
               class="responsive-action-box">
            <div>Responsive Actions</div>
            <div class="responsive-actions">
              <ng-container
                  *ngFor="let responsiveAction of questionSuggestion.config.responsive_action_item_suggestions">
                <div class="responsive-action">
                  <p class="action-title">{{responsiveAction.title}}</p>
                  <div class="state-template-checkbox">
                    <mat-checkbox [checked]="responsiveAction.is_active" disabled>Active</mat-checkbox>
                  </div>
                </div>
              </ng-container>
              <p></p>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!isLoading && template.question_suggestions.length === 0">
        <p class="empty-list">No Question Suggestion Found</p>
      </div>
    </div>
    <div *ngIf="isLoading" class="full-screen-mat-spinner">
      <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
    </div>
  </ng-template>
  <ng-template *ngIf="!isDialog" mainContainerSideNav></ng-template>
</ae-main-container>
