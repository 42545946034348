import {Injectable} from "@angular/core";
import {EntityStore, ID, StoreConfig} from "@datorama/akita";
import {UserCompact, UserFull} from "@emc-models/entities/user.model";


export interface UsersState {
  ids: ID[];
  entities: { [id: number]: UserFull | UserCompact };
  fullLoadingIds: number[];
  fullLoadedIds: number[];
  activeUserIds: number[];
  inActiveUserIds: number[];
  draftUserIds: number[];
  rejectedUserIds: number[];
  allUserIds: number[];
  activeUserOffset: number;
  inActiveUserOffset: number;
  draftUserOffset: number;
  rejectedUserOffset: number;
  allUserOffset: number;
  isActiveUsersLoading: boolean;
  isActiveUsersLoaded: boolean;
  isInActiveUsersLoading: boolean;
  isInActiveUsersLoaded: boolean;
  isDraftUsersLoading: boolean;
  isDraftUsersLoaded: boolean;
  isRejectedUsersLoading: boolean;
  isRejectedUsersLoaded: boolean;
  isAllUsersLoading: boolean;
  isAllUsersLoaded: boolean;
  selectedRoleIds: number[];
  selectedUserIds: ID[];
  startDate?: string;
  endDate?: string;
  searchQuery?: string;
  ui: {
    selectedTab: number
  };
}

const initialState  = {
  ids: [],
  entities: {},
  fullLoadingIds: [],
  fullLoadedIds: [],
  selectedRoleIds: [],
  selectedUserIds: [],

  activeUserIds: [],
  isActiveUsersLoading: false,
  isActiveUsersLoaded: false,
  activeUserOffset: 0,

  inActiveUserIds: [],
  inActiveUserOffset: 0,
  isInActiveUsersLoading: false,
  isInActiveUsersLoaded: false,

  allUserIds: [],
  allUserOffset: 0,
  isAllUsersLoading: false,
  isAllUsersLoaded: false,

  draftUserIds: [],
  draftUserOffset: 0,
  isDraftUsersLoading: false,
  isDraftUsersLoaded: false,

  rejectedUserIds: [],
  rejectedUserOffset: 0,
  isRejectedUsersLoading: false,
  isRejectedUsersLoaded: false,

  ui: {
    selectedTab: 0
  }

};

@Injectable({providedIn: "root"})
@StoreConfig({name: "users"})
export class UsersStore extends EntityStore<UsersState> {

  constructor() {
    super(initialState);
  }

  setFullLoading(userId: number) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadingIds.indexOf(userId) === -1) {
        fullLoadingIds = [
          ...fullLoadingIds,
          userId
        ];
      }
      const loadedIndex = fullLoadedIds.indexOf(userId);
      if (loadedIndex !== -1) {
        fullLoadedIds = [
          ...fullLoadedIds.slice(0, loadedIndex),
          ...fullLoadedIds.slice(loadedIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setFullLoaded(userId: number) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadedIds.indexOf(userId) === -1) {
        fullLoadedIds = [
          ...fullLoadedIds,
          userId
        ];
      }
      const loadingIndex = fullLoadingIds.indexOf(userId);
      if (loadingIndex !== -1) {
        fullLoadingIds = [
          ...fullLoadingIds.slice(0, loadingIndex),
          ...fullLoadingIds.slice(loadingIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setSelectedTab(index: number) {
    this.update({
      ui: {
        selectedTab: index
      }
    });
  }

}

