import { Injectable } from "@angular/core";
import {addRxPlugin, createRxDatabase, RxCollection, RxDatabase} from "rxdb";
import * as PouchdbAdapterIdb from "pouchdb-adapter-idb/lib";
import { environment } from "@emc-environment/environment";
import { INSPECTION_SCHEMA } from "@emc-models/schemas/inspection.schema";
import { ModelMappers } from "@emc-models/utils/model-mapper.util";

export enum RxCollections {
  MESSAGES = "messages"
}

@Injectable()
export class DbService {
  private readonly _useAdapter = "idb";
  private _db: RxDatabase;
  private _isDbResolved = false;
  private _isResolving = false;

  async init() {
    if (!this._isDbResolved && !this._isResolving) {
      this._isResolving = true;
      addRxPlugin(PouchdbAdapterIdb);
      this._db = await createRxDatabase({
        name: "alpha_emc_db",
        adapter: this._useAdapter,
        password: environment.dbPassword,
        multiInstance: false,
      });

      await this._db.collection({
        name: RxCollections.MESSAGES,
        schema: INSPECTION_SCHEMA,
        methods: {
          model() {
            return ModelMappers.mapInspection(this);
          }
        },
      });

      console.log("init", this._db);
      if (!environment.production) {
        (window as any).db = this._db;
      }
      this._isDbResolved = true;
      this._isResolving = false;
    }

    return !!this._db;
  }

  getCollection(name: RxCollections): RxCollection {
    console.log(this._db);
    return this._db?.collections[name] as RxCollection;
  }

  // Destroys the databases object-instance.
  // ref : https://rxdb.info/rx-database.html#destroy
  async destroy() {
    await this._db.destroy();
  }

  // Removes the database and wipes all data of it from the storage.
  // ref : https://rxdb.info/rx-database.html#remove
  async remove() {
    await this._db.remove();
  }

  removeInspection(id: string) {
    const query = this.getCollection(RxCollections.MESSAGES).find().where("id").eq(id);
    const removedDocs = query.remove();
    console.log("removed", removedDocs);
  }
}
