import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[aeImage]"
})
export class ImageDirective implements AfterViewInit {

  img = new Image();

  constructor(private imageRef: ElementRef) {
  }

  private _src: string;

  get src(): string {
    return this._src;
  }

  @Input() set src(src: string) {
    this.img.src = src;
    this._src = src;
  }

  ngAfterViewInit(): void {
    this.img.onload = () => {
      this.setImage(this.src);
    };

    this.img.onerror = () => {
      // Set a placeholder image
      this.setImage("assets/file_icon.svg");
    };
  }

  private setImage(src: string) {
    this.imageRef.nativeElement.setAttribute("src", src);
  }
}
