<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{title}}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <ng-container *ngIf="!eRecipientsLoading && !!eRecipients">
    <div class="emc-box">
      <h4>Provided below are the recipients of your site for the project</h4>
      <h4><b> {{projectName}} </b></h4>
      <div class="body">
        <div class="item">
          <mat-chip-list>
            <ng-container *ngFor="let email of eRecipients.emails">
              <mat-chip>{{email}}</mat-chip>
            </ng-container>

            <ng-container *ngFor="let email of eRecipients?.mailingList?.external_emails">
              <mat-chip>{{email}}</mat-chip>
            </ng-container>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="eRecipientsLoading">
    <div class="center-spinner">
      <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
    </div>
  </ng-container>
</div>
