import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ae-sub-question-card",
  templateUrl: "./sub-question-card.component.html",
  styleUrls: ["./sub-question-card.component.scss"]
})
export class SubQuestionCardComponent implements OnInit {

  @Input() group: FormGroup;
  @Input() templateId: number;
  @Output() delete = new EventEmitter();

  constructor() {
  }

  onDelete() {
    this.delete.emit();
  }

  ngOnInit() {
  }

}
