import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthManager } from "../managers/auth.manager";

@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(public router: Router, private authManager: AuthManager) {
  }

  canActivate(): boolean {
    if (this.authManager.hasAuthToken()) {
      this.router.navigateByUrl("/dashboard/home");
      return false;
    }

    return true;
  }
}
