
<div class="container">
  <button (click)="toggle()" *ngIf="isNavOver && sideNavContent && showToggleButton" [ngClass]="{'open': isSideNavOpen}"
          class="filter-button" mat-icon-button>
    <mat-icon>filter_list</mat-icon>
  </button>
  <mat-sidenav-container hasBackdrop="false">
    <mat-sidenav *ngIf="sideNavContent" [fixedInViewport]="true" [mode]='mode' [opened]="isSideNavOpen" position="end">
      <ng-container *ngTemplateOutlet="sideNavContent"></ng-container>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-container *ngTemplateOutlet="bodyContent"></ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
