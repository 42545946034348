import {Injectable} from "@angular/core";
import {ID} from "@datorama/akita";
import {CommonUtils} from "@emc-utils/common-utils";
import {Observable, Subject} from "rxjs";
import {filter, map, take, takeUntil, tap} from "rxjs/operators";
import * as _ from "lodash";
import {ExportFormat, InspectionExportFormat} from "@emc-utils/constants";
import {InspectionsQuery} from "@emc-state/inspection/inspections.query";
import {InspectionsStore} from "@emc-state/inspection/inspections.store";
import {InspectionsApiService} from "@emc-modules/inspections/services/inspections-api.service";
import {Inspection, InspectionAttachment} from "@emc-models/entities/inspection.model";
import {QuestionType} from "../../../enums/question-type.enum";
import {ResponsiveActionItem} from "@emc-models/entities/responsive-action-item.model";
import {DischargePoint} from "@emc-models/entities/discharge-point.model";
import {ERecipients} from "@emc-models/entities/project.model";
import {ApiService} from "@emc-modules/core/services/api.service";
import {EnergyInspectionType} from "../../../enums/energy-inspection-type.enum";
import {saveAs} from "file-saver/dist/FileSaver";
import {UsersStore} from "@emc-state/user/users.store";
import {QaObservation} from "@emc-models/entities/qa-inspection.model";
import * as moment from "moment";
import {SafetyObservation} from "@emc-models/entities/safety-inspection.model";
import { InspectionGroupsStore } from "../../../state/inspection/inspection-group/inspection-groups.store";
import { inspect } from "util";
import { PreAcquisitionObservation } from "../../../models/entities/pre-acquisition-inspection.model";

@Injectable({providedIn: "root"})
export class InspectionsService {

  isActionUpdated = new Subject();

  constructor(private query: InspectionsQuery,
              private inspectionsApiService: InspectionsApiService,
              private store: InspectionsStore,
              private inspectionGroupStore: InspectionGroupsStore,
              private userStore: UsersStore,
              private apiService: ApiService,
              private inspectionGroupsStore:InspectionGroupsStore
              ) {
  }

  setFilters(data: {
    client_ids: number[],
    inspector_ids: number[],
    project_ids: number[],
    search_query?: string,
    start_date: string,
    end_date: string,
    energy_inspection_types: EnergyInspectionType[];
    qa_inspection_types: number[];
  }) {

    this.store.reset();
    this.inspectionGroupStore.reset();
    this.store.update({
      selectedClientIds: data?.client_ids ? data.client_ids : [],
      selectedUserIds: data?.inspector_ids ? data.inspector_ids : [],
      selectedProjectIds: data.project_ids ? data.project_ids : [],
      searchQuery: data.search_query,
      startDate: data.start_date,
      endDate: data.end_date,
      energyInspectionTypes: data?.energy_inspection_types ? data.energy_inspection_types : [],
      QAServiceTypes: data?.qa_inspection_types ? data.qa_inspection_types : []
    });
  }

  getStormWaterSiteInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$getStormWaterSiteInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isStormWaterSiteInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isStormWaterSiteInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isStormWaterSiteInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type: "storm_water"
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$stormWaterSiteInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isStormWaterSiteInspectionLoading: false,
              stormWaterSiteInspectionOffset: _offset + inspectionIds.length,
              stormWaterSiteInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isStormWaterSiteInspectionLoading: false,
            isStormWaterSiteInspectionLoaded: true
          });
        }
      })).subscribe();
    }

    return this.query.getStormWaterSiteInspections();
  }

  getSafetyInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$getSafetyInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isSafetyInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isSafetyInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isSafetyInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type: "safety"
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$safetyInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isSafetyInspectionLoading: false,
              safetyInspectionOffset: _offset + inspectionIds.length,
              safetyInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isSafetyInspectionLoading: false,
            isSafetyInspectionLoaded: true
          });
        }
      })).subscribe();
    }
    return this.query.getSafetyInspections();
  }

  getPreAcquisitionInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;
console.log("getPreAcquisitionInspections")
    this.query.$getPreAcquisitionInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isPreAcquisitionInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isPreAcquisitionInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isPreAcquisitionInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type: "pre_acquisition"
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$preAcquisitionInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isPreAcquisitionInspectionLoading: false,
              preAcquisitionInspectionOffset: _offset + inspectionIds.length,
              preAcquisitionInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isPreAcquisitionInspectionLoading: false,
            isPreAcquisitionInspectionLoaded: true
          });
        }
      })).subscribe();
    }
    return this.query.getPreAcquisitionInspections();
  }

  getQaInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$getQaInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isQaInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isQaInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isQaInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type: "qa"
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$qaInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isQaInspectionLoading: false,
              qaInspectionOffset: _offset + inspectionIds.length,
              qaInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isQaInspectionLoading: false,
            isQaInspectionLoaded: true
          });
        }
      })).subscribe();
    }
    return this.query.getQaInspections();
  }

  getQaEnergyInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$getQaEnergyInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isQaEnergyInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isQaEnergyInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isQaEnergyInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type:"qa,energy",
        qaEnergyGroup: true
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$qaEnergyInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isQaEnergyInspectionLoading: false,
              qaEnergyInspectionOffset: _offset + inspectionIds.length,
              qaEnergyInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isQaEnergyInspectionLoading: false,
            isQaEnergyInspectionLoaded: true
          });
        }
      })).subscribe();
    }
    return this.query.getQaEnergyInspections();
  }

  getQaEnergyGroupChildrenInspections(offset = 0,client_id:number,project_id:number,home_site_lot_number?:string,street_address?:string,limit?:number) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    const key=client_id+'-'+project_id+'-'+(home_site_lot_number || '')+'-'+(street_address || '');
    this.query.$getqaEnergyGroupOffset(key)
      .pipe(take(1),map(v=>v || 0))
      .subscribe(v => _offset = v);

    this.query.$getqaEnergyGroupLoaded(key)
      .pipe(take(1),map(v=>!!v))
      .subscribe(v => _loaded = v);

    this.query.$getqaEnergyGroupLoading(key)
      .pipe(take(1),map(v=>!!v))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update((state) => {
        const previousQaEnergyGroupInspection=state.qaEnergyGroupInspection || {};
        const prev = previousQaEnergyGroupInspection[key] || {};
        return {
          ...state,
          qaEnergyGroupInspection: { ...previousQaEnergyGroupInspection,[key]: { ... prev , loading: true } },
        };
      });
      this.fetchQaEnergyChildrenInspections({
        offset,
        client_id,
        project_id,
        home_site_lot_number,
        street_address,
        limit,
      })
        .pipe(
          tap((inspections: Inspection[]) => {
            if (inspections.length > 0) {
              let oldGroupIds;
              let oldEntities;
              let oldIds;
              this.query.$qaEnergyGroupInspection
                .pipe(take(1),map((v) => v[key]?.ids || []))
                .subscribe((s) => (oldGroupIds = s));
              this.query.$entities
                .pipe(take(1))
                .subscribe((e) => (oldEntities = e));
              this.query.$qaEnergyInspectionIds
                .pipe(take(1))
                .subscribe(s => oldIds = s);
              const inspectionIds = inspections.map(
                (inspection) => inspection.id
              );
              console.log(oldIds,'oldIds');
              const entities = CommonUtils.normalize(inspections);
              this.store.update((state) => {
                state.fullLoadedIds.forEach((id) => {
                  delete entities[id];
                });
                const previousQaEnergyGroupInspection=state.qaEnergyGroupInspection || {};
                const prev = previousQaEnergyGroupInspection[key] || {};

                return {
                  qaEnergyGroupInspection: {
                    ...previousQaEnergyGroupInspection,
                    [key]: {
                      ... prev ,
                      loading:false,
                      loaded: limit>inspections.length,
                      offset: _offset + inspectionIds.length,
                      ids: _.uniq([...oldGroupIds, ...inspectionIds]),
                    },
                  },
                  qaEnergyInspectionIds: _.uniq([
                    ...oldIds,
                    ...inspectionIds
                  ]),
                  ids: _.uniq([
                    ...oldIds,
                    ...inspectionIds
                  ]),
                  entities: { ...oldEntities, ...entities },
                };
              });
            } else {
              this.store.update((state) => {
                const previousQaEnergyGroupInspection=state.qaEnergyGroupInspection || {};
                const prev = previousQaEnergyGroupInspection[key] || {};
                return {
                  qaEnergyGroupInspection: { ...previousQaEnergyGroupInspection,[key]: { ...prev , loading: false,loaded:true } },
                };
              });
            }
          })
        )
        .subscribe();
    }
    return this.query.getQaEnergyChildrenInspections(key);
  }

  getEnergyInspections(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$getEnergyInspectionOffset
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.$isEnergyInspectionLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    this.query.$isEnergyInspectionLoading
      .pipe(take(1))
      .subscribe(v => _loading = v);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isEnergyInspectionLoading: true,
      });
      this.fetchInspections({
        offset,
        inspection_type: "energy"
      }).pipe(tap((inspections: Inspection[]) => {
        if (inspections.length > 0) {
          let oldIds;
          let oldEntities;
          this.query.$energyInspectionIds
            .pipe(take(1))
            .subscribe(s => oldIds = s);
          this.query.$entities
            .pipe(take(1))
            .subscribe(e => oldEntities = e);
          const inspectionIds = inspections.map(inspection => inspection.id);
          const entities = CommonUtils.normalize(inspections);
          this.store.update(state => {
            state.fullLoadedIds.forEach(
              id => {
                delete entities[id];
              }
            );
            return {
              isEnergyInspectionLoading: false,
              energyInspectionOffset: _offset + inspectionIds.length,
              energyInspectionIds: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              ids: _.uniq([
                ...oldIds,
                ...inspectionIds
              ]),
              entities: {...oldEntities, ...entities}
            };
          });
        } else {
          this.store.update({
            isEnergyInspectionLoading: false,
            isEnergyInspectionLoaded: true
          });
        }
      })).subscribe();
    }
    return this.query.getEnergyInspections();
  }

  getInspectionById(inspectionId: number): Observable<Inspection> {
    let _loading = false;
    let _loaded = false;

    this.query.$selectIsFullLoading(inspectionId)
      .pipe(take(1))
      .subscribe(v => _loading = v);

    this.query.$selectIsFullLoaded(inspectionId)
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    if (!_loading && !_loaded) {
      this.store.setFullLoading(inspectionId);
      this.inspectionsApiService.showInspection(inspectionId)
        .subscribe((inspection: Inspection) => {
          this.store.update(state => {
            let ids = [...state.ids, inspectionId];
            ids = _.uniq(ids);
            return {
              ids,
              entities: {
                ...state.entities,
                [inspectionId]: inspection
              }
            };
          });
          this.store.setFullLoaded(inspectionId);
        });
    }
    return this.query.selectEntity(inspectionId)
      .pipe(filter((inspection: Inspection) => !!inspection && inspection.__type === "full"));
  }

  addSiteInspection(data: any): Observable<Inspection> {
    return this
      .inspectionsApiService.addSiteInspection(data)
      .pipe(tap((inspection: Inspection) => {
        this.store.add(inspection);
        this.store.update(state => {
          return {
            stormWaterSiteInspectionIds: [...state.stormWaterSiteInspectionIds, inspection.id]
          };
        });
        if (data.inspector_id && data.inspector_information) {
          this.userStore.update(data.inspector_id, {
            information: data.inspector_information
          });
        }
      }));

  }

  addSafetyInspection(data: any): Observable<Inspection> {
    return this.inspectionsApiService.addSafetyInspection(data)
      .pipe(tap((inspection: Inspection) => {
        this.store.add(inspection);
        this.store.update(state => {
          return {
            safetyInspectionIds: [...state.safetyInspectionIds, inspection.id]
          };
        });
      }));
  }

  addPreAcquisitionInspection(data: any): Observable<Inspection> {
    return this.inspectionsApiService.addPreAcquisitionInspection(data)
      .pipe(tap((inspection: Inspection) => {
        this.store.add(inspection);
        this.store.update(state => {
          return {
            preAcquisitionInspectionIds: [...state.preAcquisitionInspectionIds, inspection.id]
          };
        });
      }));
  }

  addQaInspection(data: any): Observable<Inspection> {
    return this.inspectionsApiService.addQaInspection(data).pipe(
      tap((inspection: Inspection) => {
        this.store.add(inspection);
        this.store.update((state) => {
          return {
            qaEnergyInspectionIds: [
              ...state.qaEnergyInspectionIds,
              inspection.id,
            ],
            qaEnergyGroupInspection:{}
          };
        });
        this.inspectionGroupsStore.reset();
      })
    );
  }

  addEnergyInspection(data: any): Observable<Inspection> {
    return this.inspectionsApiService.addEnergyInspection(data).pipe(tap(
      (inspection: Inspection) => {
        this.store.add(inspection);
        this.store.update(state => {
          return {
            qaEnergyGroupInspection:{},
            qaEnergyInspectionIds: [...state.qaEnergyInspectionIds, inspection.id]
          };
        });
        this.inspectionGroupsStore.reset();
      }
    ));
  }

  deleteInspection(inspectionId: ID) {
    return this.inspectionsApiService.deleteInspection(inspectionId)
      .pipe(map(() => {
        console.log("inspectionId", inspectionId);
        this.store.remove(+inspectionId);
        console.log("inspection deleted", inspectionId);
        return;
      }));
  }

  updateSiteInspection(siteInspectionId: ID, data: any): Observable<Inspection> {
    return this
      .inspectionsApiService
      .updateSiteInspection(siteInspectionId, data)
      .pipe(tap((inspection: Inspection) => {
        this.store.upsert(inspection.id, inspection);
      }));
  }

  updateSafetyInspection(safetyInspectionId: ID, data: any): Observable<Inspection> {
    return this
      .inspectionsApiService
      .updateSafetyInspection(safetyInspectionId, data)
      .pipe(tap((inspection: Inspection) => {
        this.store.upsert(inspection.id, inspection);
      }));
  }

  updatePreAcquisitionInspection(preAcquisitionInspection: ID, data: any): Observable<Inspection> {
    return this
      .inspectionsApiService
      .updatePreAcquisitionInspection(preAcquisitionInspection, data)
      .pipe(tap((inspection: Inspection) => {
        this.store.upsert(inspection.id, inspection);
      }));
  }

  updateQaInspection(qaInspectionId: ID, data: any): Observable<Inspection> {
    return this
      .inspectionsApiService
      .updateQaInspection(qaInspectionId, data)
      .pipe(tap((inspection: Inspection) => {
        this.store.upsert(inspection.id, inspection);
        this.inspectionGroupsStore.reset();
        this.store.update(state => {
          return {
            qaEnergyGroupInspection:{},
          };
        });
      }));
  }

  updateHomeNotReadyValue(inspectionId: ID, data: boolean): any {
    this.store.update(inspectionId, i => {
      return {
        ...i,
        qaInspection: {
          ...i.qaInspection,
          home_not_ready: data
        }
      };
    });
  }

  followUpInspection(inspectionId: ID): Observable<Inspection> {
    return this
      .inspectionsApiService
      .followUpInspection(inspectionId)
      .pipe(tap((inspection: Inspection) => {
        this.store.update(inspectionId, i => {
          return {
            ...i,
            qaInspection: {
              ...i.qaInspection,
              has_followed_up: true
            }
          };
        });
        this.store.update(state => {
          return {
            qaInspectionIds: _.uniq([...state.qaInspectionIds, inspection.id])
          };
        });
        this.store.upsert(inspection.id, inspection);
        this.store.setFullLoaded(inspection.id);
      }));
  }

  updateEnergyInspection(energyInspectionId: ID, data: any): Observable<Inspection> {
    return this.inspectionsApiService.updateEnergyInspection(energyInspectionId, data).pipe(tap(
      (inspection: Inspection) => {
        this.store.upsert(inspection.id, inspection);
        this.inspectionGroupsStore.reset();
        this.store.update(state => {
          return {
            qaEnergyGroupInspection:{},
          };
        });
      }
    ));
  }

  exportFinaliseInspections(format: InspectionExportFormat, inspectionIds: ID[], allSelected: boolean, filters?: any): Observable<void> {
    let data: any = {
      format
    };

    if (allSelected) {

      if (filters.inspection_type) {
        data.inspection_type = filters.inspection_type;
      }

      if (filters?.start_date) {
        data.start_date = moment(filters.start_date).format("YYYY-MM-DD");
      }

      if (filters?.end_date) {
        data.end_date = moment(filters.end_date).format("YYYY-MM-DD");
      }

      if (filters?.client_ids?.length) {
        data.client_ids = filters.client_ids;
      }

      if (filters?.search_query) {
        data.query = filters.search_query;
      }

      if (filters?.inspector_ids?.length) {
        data.inspection_ids = filters.inspector_ids;
      }

      if (filters?.project_ids?.length) {
        data.project_ids = filters.project_ids;
      }

      if (filters?.energy_inspection_types?.length) {
        data.energy_inspection_types = filters.energy_inspection_types;
      }
      if (filters?.qa_inspection_types?.length) {
        data.qa_inspection_types = filters.qa_inspection_types;
      }

      data = {
        ...data,
        export_all: allSelected,
      };
    } else {
      data = {
        ...data,
        inspection_ids: inspectionIds
      };
    }

    return this.inspectionsApiService.exportFinaliseInspections(data);
  }

  exportNormalInspections(format: ExportFormat, inspectionIds: ID[], type: string, allSelected: boolean, filters?: any): Observable<any> {
    let data: any = {
      format
    };

    if (allSelected) {

      if (filters?.start_date) {
        data.start_date = moment(filters.start_date).format("YYYY-MM-DD");
      }

      if (filters?.end_date) {
        data.end_date = moment(filters.end_date).format("YYYY-MM-DD");
      }

      if (filters?.client_ids?.length) {
        data.client_ids = filters.client_ids;
      }

      if (filters?.search_query) {
        data.query = filters.search_query;
      }

      if (filters?.inspector_ids?.length) {
        data.inspection_ids = filters.inspector_ids;
      }

      if (filters?.project_ids?.length) {
        data.project_ids = filters.project_ids;
      }

      if (filters?.energy_inspection_types?.length) {
        data.energy_inspection_types = filters.energy_inspection_types;
      }
      if (filters?.qa_inspection_types?.length) {
        data.qa_inspection_types = filters.qa_inspection_types;
      }

      data = {
        ...data,
        export_all: allSelected,
      };
    } else {

      data = {
        ...data,
        inspection_ids: inspectionIds
      };
    }

    return this.inspectionsApiService.exportNormalInspections(data, type);

  }

  exportSafetyAssessmentReportExcel(data: any): Observable<any> {
    return this.inspectionsApiService.exportSafetyAssessmentReportExcel(data);
  }

  exportSafetyAssessmentReportPDF(data: any): Observable<any> {
    return this.inspectionsApiService.exportSafetyAssessmentReportPDF(data);
  }

  getPriorFinalizedItemsLoading(inspectionId: number): Observable<boolean> {
    return this.query.$selectIsPriorFinalizedActionItemsLoading(inspectionId);
  }

  getPriorFinalizedItemsLoaded(inspectionId: number): Observable<boolean> {
    return this.query.$selectIsPriorFinalizedActionItemsLoaded(inspectionId);
  }

  getPriorFinalizedItems(projectId: number, inspectionId?: number): Observable<ResponsiveActionItem[]> {
    return this.inspectionsApiService.getPriorFinalizedItems(projectId, inspectionId);
  }

  exportOpenActionItems(projectId: number, inspectionId?: number): Observable<any> {
    return this.inspectionsApiService.exportOpenActionItems(projectId, inspectionId);
  }

  getOpenActionItems(projectId: number, inspectionId?: number): Observable<ResponsiveActionItem[]> {
    return this.inspectionsApiService.getOpenActionItems(projectId, inspectionId);
  }

  updateResponsiveActionItem(itemId: number, inspectionId: number, data: any): Observable<ResponsiveActionItem> {
    return this.inspectionsApiService.updateResponsiveActionItem(itemId, data)
      .pipe(tap(res => {
        this.store.update(state => {
          let items = state.priorFinalizedActionItems[inspectionId] || [];
          const index = items.findIndex(i => i.id === itemId);
          if (index !== -1) {
            items = [
              ...items.slice(0, index),
              res,
              ...items.slice(index + 1)
            ];
          }

          return {
            priorFinalizedActionItems: {
              ...state.priorFinalizedActionItems,
              [inspectionId]: items
            }
          };
        });
      }));
  }

  bulkUpdateResponsiveActionItem(ids: number[], data: any): Observable<ResponsiveActionItem[]> {
    return this.inspectionsApiService.bulkUpdateResponsiveActionItem(data)
      .pipe(tap((val) => {
        let newState = {};
        if (data?.completed_on) {
          newState = {
            completed_on: data.completed_on
          };
        }
        if (data?.completed_by) {
          newState = {
            ...newState,
            completed_by: data.completed_by
          };
        }
        if (data?.noted_on) {
          newState = {
            ...newState,
            created_at: data.noted_on
          };
        }
        // @ts-ignore
        this.store.upsert(ids, newState);
      }));
  }

  deleteResponsiveActionItem(itemId: number, inspectionId: number): Observable<void> {
    return this.inspectionsApiService.deleteResponsiveActionItem(itemId)
      .pipe(tap(() => {
        this.store.update(state => {
          let items = state.priorFinalizedActionItems[inspectionId] || [];
          const index = items.findIndex(i => i.id === itemId);
          if (index !== -1) {
            items = [
              ...items.slice(0, index),
              ...items.slice(index + 1)
            ];
          }
          return {
            priorFinalizedActionItems: {
              ...state.priorFinalizedActionItems,
              [inspectionId]: items
            }
          };
        });
      }));
  }

  deleteDischargePoint(id: number, projectId: number): Observable<void> {
    return this.inspectionsApiService.deleteDischargePoint(id)
      .pipe(tap(() => {
        this.store.update(state => {
            let items = state.dischargePoints[projectId] || [];
            const index = items.findIndex(i => i.id === id);
            if (index !== -1) {
              items = [
                ...items.slice(0, index),
                ...items.slice(index + 1)
              ];
            }
            return {
              dischargePoints: {
                ...state.dischargePoints,
                [projectId]: items
              }
            };
          }
        );
      }));
  }

  resetProjectDischargePoints(projectId: number) {
    this.store.update(state => {
      return {
        dischargePointsLoaded: {
          ...state.dischargePointsLoaded,
          [projectId]: false
        }
      };
    });
  }

  getDischargePointsLoading(projectId: number): Observable<boolean> {
    return this.query.$selectIsDischargePointsLoading(projectId);
  }

  getDischargePointsLoaded(projectId: number): Observable<boolean> {
    return this.query.$selectIsDischargePointsLoaded(projectId);
  }

  getDischargePointsForProject(projectId: number): Observable<DischargePoint[]> {
    let loading: boolean;
    let loaded: boolean;

    this.getDischargePointsLoading(projectId).pipe(take(1))
      .subscribe(l => loading = l);

    this.getDischargePointsLoaded(projectId).pipe(take(1))
      .subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.setDischargePointsLoading(projectId);
      this.inspectionsApiService.getDischargePointsForProject(projectId)
        .subscribe(points => {
          this.store.update({
            dischargePoints: {
              [projectId]: points
            }
          });
          this.store.setDischargePointsLoaded(projectId);
        });
    }

    return this.query.$selectDischargePoints(projectId).pipe(filter(d => !!d));
  }

  getERecipientsLoading(projectId: number, inspectionType: string): Observable<boolean> {
    return this.query.$selectIsERecepientsLoading(projectId, inspectionType);
  }

  getERecipientsLoaded(projectId: number, inspectionType: string): Observable<boolean> {
    return this.query.$selectIsERecepientsLoaded(projectId, inspectionType);
  }

  getERecipientsForProject(projectId: number, inspectionType: string): Observable<ERecipients> {
    let loading: boolean;
    let loaded: boolean;

    this.getERecipientsLoading(projectId, inspectionType).pipe(take(1))
      .subscribe(l => loading = l);

    this.getERecipientsLoaded(projectId, inspectionType).pipe(take(1))
      .subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.setERecipientsLoading(projectId, inspectionType);
      this.inspectionsApiService.getERecipientsForProject(projectId, inspectionType)
        .subscribe(recipients => {
          this.store.update({
            eRecipients: {
              [`${projectId}-${inspectionType}`]: recipients
            }
          });
          this.store.setERecipientsLoaded(projectId, inspectionType);
        });
    }

    return this.query.$selectERecipients(projectId, inspectionType).pipe(filter(d => !!d));
  }

  deleteAttachments(id: number): Observable<void> {
    return this.inspectionsApiService.deleteAttachment(id);
  }

  exportInspection(id: ID, format: "pdf" | "docx"): Observable<void> {
    return this.inspectionsApiService.exportInspection(id, format);
  }

  remail(id: ID): Observable<void> {
    return this.inspectionsApiService.remail(id);
  }

  getProjectMarkersLoading(projectId: number): Observable<boolean> {
    return this.query.$selectIsProjectMarkersLoading(projectId);
  }

  getProjectMarkersLoaded(projectId: number): Observable<boolean> {
    return this.query.$selectIsProjectMarkersLoaded(projectId);
  }

  getProjectMarkers(projectId: number): Observable<ResponsiveActionItem[]> {
    let loading: boolean;
    let loaded: boolean;

    this.getProjectMarkersLoading(projectId).pipe(take(1)).subscribe(l => loading = l);
    this.getProjectMarkersLoaded(projectId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.setProjectMarkersLoading(projectId);
      this.inspectionsApiService.getProjectMarkers(projectId)
        .subscribe(markers => {
          this.store.update({
            projectMarkers: {
              [projectId]: markers
            }
          });
          this.store.setProjectMarkersLoaded(projectId);
        });
    }

    return this.query.$selectMarkersForProject(projectId).pipe(filter(s => !!s));
  }

  sswrCertifyInspection(inspectionId: number): Observable<Inspection> {
    return this.inspectionsApiService.sswrCertifyInspection(inspectionId)
      .pipe(map(inspection => {
        this.store.update(inspectionId, inspection);
        return inspection;
      }));
  }

  setSelectedIndex(index: number) {
    this.store.setSelectedTab(index);
  }

  downloadInspectionAttachment(attachment: InspectionAttachment): void {
    saveAs(attachment.attachment_url, attachment.attachment_name);
  }

  fetchLastSiteInspectionComment(projectId: number): Observable<string> {
    return this.inspectionsApiService.fetchLastSiteInspectionComment(projectId);
  }

  fetchLastMarylandInspectionInfo(projectId: number): Observable<{ certification_number: string, earth_disturbance_date: string }> {
    return this.inspectionsApiService.fetchLastMarylandInspectionInfo(projectId);
  }

  fetchLastPulteInspectionDetails(projectId: number): Observable<{ recent_storm_event_date: string, rainfall_information: string, master_site_list_id: string, site_location: string }> {
    return this.inspectionsApiService.fetchLastPulteInspectionDetails(projectId);
  }

  deleteQaObservation(observationId: number, inspectionId: number): Observable<void> {
    return this.inspectionsApiService.deleteQaObservation(observationId)
      .pipe(map(() => {
        this.store.update(inspectionId, (inspection) => {
          const index = inspection.qaInspection.qaObservations.findIndex(
            (q) => q.id === observationId
          );
          const previousQaObservation =
            inspection.qaInspection.qaObservations[index];
          const qaInspection = inspection.qaInspection;
          let observations = [];
          if (qaInspection) {
            observations = qaInspection.qaObservations || [];
            const index = observations.findIndex((i) => i.id === observationId);
            if (index !== -1) {
              observations = [
                ...observations.slice(0, index),
                ...observations.slice(index + 1),
              ];
            }
          }

          return {
            ...inspection,
            qaInspection: {
              ...inspection.qaInspection,
              no_of_qa_1_items:
              inspection.qaInspection.follow_up_reference_number === 1
                ? inspection.qaInspection.no_of_qa_1_items - 1
                : inspection.qaInspection.no_of_qa_1_items,
            no_of_qa_2_items:
              inspection.qaInspection.follow_up_reference_number > 1
                ? inspection.qaInspection.no_of_qa_2_items - 1
                : inspection.qaInspection.no_of_qa_2_items,
            minorMajorItemCount: {
              no_of_major_items:
                inspection.qaInspection.minorMajorItemCount.no_of_major_items -
                (previousQaObservation.follow_up_reference_number===1 && (previousQaObservation.issue.is_minor
                  ? 0
                  : previousQaObservation?.issue_count)),
              no_of_minor_items:
                inspection.qaInspection.minorMajorItemCount.no_of_minor_items -
                (previousQaObservation.follow_up_reference_number===1 && (previousQaObservation.issue.is_minor
                  ? previousQaObservation?.issue_count
                  : 0)),
            },
              qaObservations: observations,
            },
          };
        });
        return;
      }));
  }

  addQaObservation(qaInspectionId: number, inspectionId: number, data: any): Observable<QaObservation> {
    return this.inspectionsApiService.addQaObservation(qaInspectionId, data)
      .pipe(map((qaObservation: QaObservation) => {
        this.store.update(inspectionId, inspection => {
          return {
            ...inspection,
            qaInspection: {
              ...inspection.qaInspection,
              no_of_qa_1_items:
                inspection.qaInspection.follow_up_reference_number === 1
                  ? inspection.qaInspection.no_of_qa_1_items + 1
                  : inspection.qaInspection.no_of_qa_1_items,
              no_of_qa_2_items:
                inspection.qaInspection.follow_up_reference_number > 1
                  ? inspection.qaInspection.no_of_qa_2_items + 1
                  : inspection.qaInspection.no_of_qa_2_items,
              minorMajorItemCount: {
                no_of_major_items:
                  inspection.qaInspection.minorMajorItemCount
                    .no_of_major_items + (qaObservation.follow_up_reference_number===1 && (qaObservation.issue.is_minor ? 0 : qaObservation?.issue_count)),
                no_of_minor_items:
                  inspection.qaInspection.minorMajorItemCount
                    .no_of_minor_items + (qaObservation.follow_up_reference_number===1 && (qaObservation.issue.is_minor ? qaObservation?.issue_count : 0)),
              },
              qaObservations: [
                ...inspection.qaInspection.qaObservations,
                qaObservation,
              ],
            },
          };
        });
        return qaObservation;
      }));
  }

  updateQaObservation(observationId: number, inspectionId: number, data: any): Observable<QaObservation> {

    return this.inspectionsApiService.updateQaObservation(observationId, data)
      .pipe(map((qaObservation: QaObservation) => {
        this.store.update(inspectionId, (inspection) => {
          const index = inspection.qaInspection.qaObservations.findIndex(
            (q) => q.id === observationId
          );
          const previousQaObservation =
            inspection.qaInspection.qaObservations[index];
          let no_of_major_items = 0,
            no_of_minor_items = 0;

          const difference=qaObservation.issue_count-previousQaObservation.issue_count;
          if (
            qaObservation.issue.is_minor !==
            previousQaObservation.issue.is_minor
          ) {
            if (qaObservation.issue.is_minor) {
              no_of_major_items = -previousQaObservation.issue_count;;
              no_of_minor_items = qaObservation.issue_count;
            } else {
              no_of_major_items = qaObservation.issue_count;
              no_of_minor_items = -previousQaObservation.issue_count;
            }
          }
          else{
            if (qaObservation.issue.is_minor) {
              no_of_minor_items = difference;
            } else {
              no_of_major_items = difference;
            }
          }

          if (index !== -1) {
            return {
              ...inspection,
              qaInspection: {
                ...inspection.qaInspection,
                minorMajorItemCount: {
                  no_of_major_items:
                    inspection.qaInspection.minorMajorItemCount
                      .no_of_major_items + (qaObservation.follow_up_reference_number===1 && no_of_major_items),
                  no_of_minor_items:
                    inspection.qaInspection.minorMajorItemCount
                      .no_of_minor_items + (qaObservation.follow_up_reference_number===1 && no_of_minor_items),
                },
                qaObservations: [
                  ...inspection.qaInspection.qaObservations.slice(0, index),
                  qaObservation,
                  ...inspection.qaInspection.qaObservations.slice(index + 1),
                ],
              },
            };
          }

          return {
            ...inspection,
            qaInspection: {
              ...inspection.qaInspection,
              minorMajorItemCount: {
                no_of_major_items:
                  inspection.qaInspection.minorMajorItemCount
                    .no_of_major_items + (qaObservation.follow_up_reference_number===1 && (qaObservation.issue.is_minor ? 0 : qaObservation?.issue_count)),
                no_of_minor_items:
                  inspection.qaInspection.minorMajorItemCount
                    .no_of_minor_items + (qaObservation.follow_up_reference_number===1 && (qaObservation.issue.is_minor ? qaObservation?.issue_count : 0)),
              },
              qaObservations: [
                ...inspection.qaInspection.qaObservations,
                qaObservation,
              ],
            },
          };
        });
        return qaObservation;
      }));
  }

  addSafetyObservation(safetyInspectionId: number, inspectionId: number, data: any): Observable<SafetyObservation> {
    return this.inspectionsApiService.addSafetyObservation(safetyInspectionId, data)
      .pipe(map((safetyObservation: SafetyObservation) => {
        this.store.update(inspectionId, inspection => {
          return {
            ...inspection,
            safetyInspection: {
              ...inspection.safetyInspection,
              safetyObservations: [
                ...inspection.safetyInspection.safetyObservations,
                safetyObservation
              ]
            }
          };
        });
        return safetyObservation;
      }));
  }

  updateSafetyObservation(observationId: number, inspectionId: number, data: any): Observable<SafetyObservation> {

    return this.inspectionsApiService.updateSafetyObservation(observationId, data)
      .pipe(map((safetyObservation: SafetyObservation) => {
        this.store.update(inspectionId, inspection => {
          const index = inspection.safetyInspection.safetyObservations.findIndex(q => q.id === observationId);

          if (index !== -1) {
            return {
              ...inspection,
              safetyInspection: {
                ...inspection.safetyInspection,
                safetyObservations: [
                  ...inspection.safetyInspection.safetyObservations.slice(0, index),
                  safetyObservation,
                  ...inspection.safetyInspection.safetyObservations.slice(index + 1)
                ]
              }
            };
          }

          return {
            ...inspection,
            safetyInspection: {
              ...inspection.safetyInspection,
              safetyObservations: [
                ...inspection.safetyInspection.safetyObservations,
                safetyObservation
              ]
            }
          }; });
        return safetyObservation;
      }));
  }

  deleteSafetyObservation(observationId: number, inspectionId: number): Observable<void> {
    return this.inspectionsApiService.deleteSafetyObservation(observationId)
      .pipe(map(() => {
        this.store.update(inspectionId, inspection => {
          const safetyInspection = inspection.safetyInspection;
          let observations = [];
          if (safetyInspection) {
            observations = safetyInspection.safetyObservations || [];
            const index = observations.findIndex(i => i.id === observationId);
            if (index !== -1) {
              observations = [
                ...observations.slice(0, index),
                ...observations.slice(index + 1)
              ];
            }
          }
          return {
            ...inspection,
            safetyInspection: {
              ...inspection.safetyInspection,
              safetyObservations: observations
            }
          };
        });
        return;
      }));
  }

  addPreAcquisitionObservation(preAcquisitionInspectionId: number, inspectionId: number, data: any): Observable<PreAcquisitionObservation> {
    return this.inspectionsApiService.addPreAcquisitionObservation(preAcquisitionInspectionId, data)
      .pipe(map((preAcquisitionObservation: PreAcquisitionObservation) => {
        this.store.update(inspectionId, inspection => {
          return {
            ...inspection,
            preAcquisitionInspection: {
              ...inspection.preAcquisitionInspection,
              preAcquisitionObservations: [
                ...inspection.preAcquisitionInspection.preAcquisitionObservations,
                preAcquisitionObservation
              ]
            }
          };
        });
        return preAcquisitionObservation;
      }));
  }

  updatePreAcquisitionObservation(observationId: number, inspectionId: number, data: any): Observable<PreAcquisitionObservation> {

    return this.inspectionsApiService.updatePreAcquisitionObservation(observationId, data)
      .pipe(map((preAcquisitionObservation: PreAcquisitionObservation) => {
        this.store.update(inspectionId, inspection => {
          const index = inspection.preAcquisitionInspection.preAcquisitionObservations.findIndex(q => q.id === observationId);

          if (index !== -1) {
            return {
              ...inspection,
              preAcquisitionInspection: {
                ...inspection.preAcquisitionInspection,
                preAcquisitionObservations: [
                  ...inspection.preAcquisitionInspection.preAcquisitionObservations.slice(0, index),
                  preAcquisitionObservation,
                  ...inspection.preAcquisitionInspection.preAcquisitionObservations.slice(index + 1)
                ]
              }
            };
          }

          return {
            ...inspection,
            preAcquisitionInspection: {
              ...inspection.preAcquisitionInspection,
              preAcquisitionObservations: [
                ...inspection.preAcquisitionInspection.preAcquisitionObservations,
                preAcquisitionObservation
              ]
            }
          }; });
        return preAcquisitionObservation;
      }));
  }

  deletePreAcquisitionObservation(observationId: number, inspectionId: number): Observable<void> {
    return this.inspectionsApiService.deletePreAcquisitionObservation(observationId)
      .pipe(map(() => {
        this.store.update(inspectionId, inspection => {
          const preAcquisitionInspection = inspection.preAcquisitionInspection;
          let observations = [];
          if (preAcquisitionInspection) {
            observations = preAcquisitionInspection.preAcquisitionObservations || [];
            const index = observations.findIndex(i => i.id === observationId);
            if (index !== -1) {
              observations = [
                ...observations.slice(0, index),
                ...observations.slice(index + 1)
              ];
            }
          }
          return {
            ...inspection,
            preAcquisitionInspection: {
              ...inspection.preAcquisitionInspection,
              preAcquisitionObservations: observations
            }
          };
        });
        return;
      }));
  }


  private fetchInspections(data: { offset: number, inspection_type: string,qaEnergyGroup?:boolean  }): Observable<Inspection[]> {
    let _selectedUserIds = [];
    let _selectedClientIds = [];
    let _selectedProjectIds = [];
    let _searchQuery: string;

    const _data = {
      offset: data.offset,
      qaEnergyGroup:!!data.qaEnergyGroup,
    } as any;

    this.query.$selectedUserIds
      .pipe(take(1))
      .subscribe(v => _selectedUserIds = v);

    this.query.$selectedClientIds
      .pipe(take(1))
      .subscribe(v => _selectedClientIds = v);

    this.query.$selectedProjectIds
      .pipe(take(1))
      .subscribe(p => _selectedProjectIds = p);

    this.query.$selectedStartDate
      .pipe(take(1), filter(s => !!s))
      .subscribe(v => _data.start_date = v);


    this.query.$selectedEndDate
      .pipe(take(1), filter(s => !!s))
      .subscribe(v => _data.end_date = v);

    this.query.$searchQuery
      .pipe(take(1))
      .subscribe(v => _searchQuery = v);

    if (data.inspection_type === QuestionType.ENERGY  || data.inspection_type===QuestionType.QA_ENERGY) {
      this.query.$selectEnergyInspectionTypes
        .pipe(take(1), filter(t => !!t && t.length > 0))
        .subscribe(t => _data.energy_inspection_types = t);
    }

    if (data.inspection_type === QuestionType.QA || data.inspection_type===QuestionType.QA_ENERGY) {
      this.query.$selectQAServiceTypes
        .pipe(take(1), filter(t => !!t && t.length > 0))
        .subscribe(t => _data.qa_inspection_types = t);
    }

    if (data.inspection_type) {
      _data.inspection_type = data.inspection_type;
    }

    if (_selectedUserIds.length > 0) {
      _data.inspector_ids = _selectedUserIds;
    }

    if (_selectedClientIds.length > 0) {
      _data.client_ids = _selectedClientIds;
    }

    if (_selectedProjectIds.length > 0) {
      _data.project_ids = _selectedProjectIds;
    }

    if (!!_searchQuery) {
      _data.query = _searchQuery;
    }
    return this.inspectionsApiService.list(_data);
  }

  private fetchQaEnergyChildrenInspections(data: {limit?:number; offset: number,client_id:number,project_id:number,home_site_lot_number?:string,street_address?:string }): Observable<Inspection[]> {
    let _selectedUserIds = [];
    let _searchQuery: string;

    const _data = {
      offset: data.offset,
      client_ids:[data.client_id],
      project_ids:[data.project_id],
      home_site_lot_number:data.home_site_lot_number || '',
      street_address:data.street_address || '',
      limit:data.limit || 30,
    } as any;

    this.query.$selectedUserIds
      .pipe(take(1))
      .subscribe(v => _selectedUserIds = v);

    this.query.$selectedStartDate
      .pipe(take(1), filter(s => !!s))
      .subscribe(v => _data.start_date = v);


    this.query.$selectedEndDate
      .pipe(take(1), filter(s => !!s))
      .subscribe(v => _data.end_date = v);

    this.query.$searchQuery
      .pipe(take(1))
      .subscribe(v => _searchQuery = v);

    this.query.$selectEnergyInspectionTypes
        .pipe(take(1), filter(t => !!t && t.length > 0))
        .subscribe(t => _data.energy_inspection_types = t);


    this.query.$selectQAServiceTypes
        .pipe(take(1), filter(t => !!t && t.length > 0))
        .subscribe(t => _data.qa_inspection_types = t);

    if (_selectedUserIds.length > 0) {
      _data.inspector_ids = _selectedUserIds;
    }

    if (!!_searchQuery) {
      _data.query = _searchQuery;
    }

    _data.inspection_type = QuestionType.QA_ENERGY;
    
    return this.inspectionsApiService.list(_data);
  }
}
