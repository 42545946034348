import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { noop } from "rxjs";
import { AlertService } from "@emc-modules/core/services/alert.service";
import * as Hammer from "hammerjs";

@Component({
  selector: "ae-map-engine",
  templateUrl: "./map-engine.component.html",
  styleUrls: ["./map-engine.component.scss"]
})
export class MapEngineComponent implements OnInit {
  @ViewChild("currentImage", { static: true }) currentImage: ElementRef;
  @ViewChild("currentImageWrapper", { static: true }) currentImageWrapper: ElementRef;
  @Input() initialIndex = 0;
  @Input() maps: MapData[];
  @Input() mapOperationMode: MapOperationMode;
  @Input() inspectionList: { title: string, id: number }[] = [];
  @Output() mapUpdated = new EventEmitter<MapData>();
  @Output() allPinsRemoved = new EventEmitter<any>();
  @Output() newInspectionSelected = new EventEmitter<number>();
  lastScale = 0;

  allowedPinColors = ["#1abc9c", "#c0392b", "#2980b9", "#8e44ad", "#f1c40f", "#27ae60", "#95a5a6"];
  currentMap: MapData;
  isCurrentMapDownloaded = false;
  currentMapIndex = 0;
  currentMapState: {
    zoomScale: number,
    translateX: number,
    translateY: number
  } = { zoomScale: 1, translateX: 0, translateY: 0 };
  isPinDropped = false;
  panningState: { oldX: number, oldY: number } = { oldX: null, oldY: null };
  pinXDimensionData = null;
  dropPinType = 0;
  MapOperationMode = MapOperationMode;
  xDimensionTimer: number;
  isPinDragging: boolean;
  yDimensionTimer = null;
  pinYDimensionData = null;
  isPinching: boolean;

  constructor(private _alert: AlertService, private changeRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.initialIndex > this.maps.length) {
      this._alert.error("Wrong Initial Index");
    } else {
      this.currentMapIndex = this.initialIndex;
    }
    this.currentMap = this.maps[this.currentMapIndex];
    if (this.currentMap.pins && this.currentMap.pins.length) {
      this.isPinDropped = true;
    }
    this.verifyInputData() ? noop() : this._alert.error("This seems wrong, all your map uIds are not UNIQUE");
    this.loadAndRenderCurrentMap();

    const hammerTime = new Hammer(this.currentImageWrapper.nativeElement, {
      domEvents: true
    });
    hammerTime.get("pinch").set({enable: true, interval: 700}).dropRecognizeWith(hammerTime.get("pan").set({enable: true}));

    hammerTime.on("pinch", event => {
      const scale = +(Math.round(event.scale * 100) / 100).toFixed(2);
      if (scale !== this.lastScale) {
        if (event.scale > 1) {
          this.zoomIn(0.05);
        } else {
          this.zoomOut(0.05);
        }
      }
      this.lastScale = scale;
    });

    hammerTime.on("pinchstart", () => this.isPinching = true);
    hammerTime.on("pinchend", () => this.isPinching = false);

    hammerTime.on("pan", event => {
      if (!this.isPinching && !this.isPinDragging && event.pointerType !== "mouse") {
        this.handleHammerPan(event);
      }
    });
  }

  handleHammerPan(e: HammerInput) {
    if (this.isPinDragging) {
      return;
    }
    // if any distance is positive, it means image overflows its container from that side.
    const mapTop = this.currentImageWrapper.nativeElement.getBoundingClientRect().top - this.currentImage.nativeElement.getBoundingClientRect().top;
    const mapBottom = (this.currentImageWrapper.nativeElement.getBoundingClientRect().bottom - this.currentImage.nativeElement.getBoundingClientRect().bottom) * -1;
    const mapLeft = this.currentImageWrapper.nativeElement.getBoundingClientRect().left - this.currentImage.nativeElement.getBoundingClientRect().left;
    const mapRight = (this.currentImageWrapper.nativeElement.getBoundingClientRect().right - this.currentImage.nativeElement.getBoundingClientRect().right) * -1;

    if (e.deltaX && e.deltaY) {
      console.log("e", e.deltaX, e.deltaY);
      const xDiff = this.panningState.oldX ? (e.deltaX / 50) + this.panningState.oldX : 0;
      const yDiff = this.panningState.oldY ? (e.deltaY / 50) + this.panningState.oldY : 0;

      // overflow lock
      if ((yDiff > 0 && mapTop >= 0) || (yDiff < 0 && mapBottom >= 0)) {
        this.currentMapState.translateY = this.currentMapState.translateY + yDiff;
      }

      if ((xDiff > 0 && mapLeft >= 0) || (xDiff < 0 && mapRight >= 0)) {
        this.currentMapState.translateX = this.currentMapState.translateX + xDiff;
      }

      // underflow lock
      if ((yDiff < 0 && mapTop < 0) || (yDiff > 0 && mapBottom < 0)) {
        this.currentMapState.translateY = this.currentMapState.translateY + yDiff;
      }

      if ((xDiff < 0 && mapLeft < 0) || (xDiff > 0 && mapRight < 0)) {
        this.currentMapState.translateX = this.currentMapState.translateX + xDiff;
      }

      this.performZoomPanOperation();
      this.panningState.oldX = e.deltaX / 50;
      this.panningState.oldY = e.deltaY / 50;
    }

    e.preventDefault();
  }

  previousMap() {
    this.currentMapIndex = this.currentMapIndex === 0 ? this.maps.length - 1 : this.currentMapIndex - 1;
    this.currentMap = this.maps[this.currentMapIndex];
    this.refreshStates();
    this.loadAndRenderCurrentMap();
  }

  nextMap() {
    this.currentMapIndex = (this.currentMapIndex + 1) % this.maps.length;
    this.currentMap = this.maps[this.currentMapIndex];
    this.refreshStates();
    this.loadAndRenderCurrentMap();
  }


  clearPins() {
    for (const map of this.maps) {
      map.pins = [];
      // since mode is strict,
      // we need to first clear all the pins, then add pin to the clicked location
    }
    this.allPinsRemoved.emit("removed");
  }

  refreshStates() {
    this.currentMapState = { zoomScale: 1, translateX: 0, translateY: 0 };
    this.pinXDimensionData = null;
    this.pinYDimensionData = null;
    this.xDimensionTimer = null;
    this.yDimensionTimer = null;
    // if (!this.currentMap.pins || !this.currentMap.pins.length) {
    //   this.isPinDropped = false;
    // }
    this.performZoomPanOperation();
  }

  save() {

    this.mapUpdated.emit(this.maps[this.currentMapIndex]);
  }

  zoomIn(zoomPowerFactor = 0.1) {
    // max 5x zoom.
    this.currentMapState.zoomScale = this.currentMapState.zoomScale < 5 ? this.currentMapState.zoomScale + zoomPowerFactor : 5;
    this.performZoomPanOperation();
  }

  zoomOut(zoomPowerFactor = 0.1) {
    // max 0.1x zoom
    this.currentMapState.zoomScale = this.currentMapState.zoomScale < 0.2 ? 0.1 : this.currentMapState.zoomScale - zoomPowerFactor;
    this.performZoomPanOperation();
  }

  handleMousePadPinch(e: WheelEvent) {
    e.preventDefault();
    if (e.ctrlKey) {
      if (e.deltaY > 0) {
        this.zoomOut(0.05);
      } else {
        this.zoomIn(0.05);
      }
    }
  }

  handleMapPanning(e: DragEvent) {
    if (this.isPinDragging) {
      return;
    }
    // if any distance is positive, it means image overflows its container from that side.
    const mapTop = this.currentImageWrapper.nativeElement.getBoundingClientRect().top - this.currentImage.nativeElement.getBoundingClientRect().top;
    const mapBottom = (this.currentImageWrapper.nativeElement.getBoundingClientRect().bottom - this.currentImage.nativeElement.getBoundingClientRect().bottom) * -1;
    const mapLeft = this.currentImageWrapper.nativeElement.getBoundingClientRect().left - this.currentImage.nativeElement.getBoundingClientRect().left;
    const mapRight = (this.currentImageWrapper.nativeElement.getBoundingClientRect().right - this.currentImage.nativeElement.getBoundingClientRect().right) * -1;

    if (e.clientX && e.clientY) {
      const xDiff = this.panningState.oldX ? e.clientX - this.panningState.oldX : 0;
      const yDiff = this.panningState.oldY ? e.clientY - this.panningState.oldY : 0;

      // overflow lock
      if ((yDiff > 0 && mapTop >= 0) || (yDiff < 0 && mapBottom >= 0)) {
        this.currentMapState.translateY = this.currentMapState.translateY + yDiff;
      }

      if ((xDiff > 0 && mapLeft >= 0) || (xDiff < 0 && mapRight >= 0)) {
        this.currentMapState.translateX = this.currentMapState.translateX + xDiff;
      }

      // underflow lock
      if ((yDiff < 0 && mapTop < 0) || (yDiff > 0 && mapBottom < 0)) {
        this.currentMapState.translateY = this.currentMapState.translateY + yDiff;
      }

      if ((xDiff < 0 && mapLeft < 0) || (xDiff > 0 && mapRight < 0)) {
        this.currentMapState.translateX = this.currentMapState.translateX + xDiff;
      }

      this.performZoomPanOperation();
      this.panningState.oldX = e.clientX;
      this.panningState.oldY = e.clientY;
    }

    e.preventDefault();
  }

  performZoomPanOperation() {
    // tslint:disable-next-line:max-line-length
    this.currentImage.nativeElement.style.transform = `scale(${this.currentMapState.zoomScale}) translate(${this.currentMapState.translateX / this.currentMapState.zoomScale}px, ${this.currentMapState.translateY / this.currentMapState.zoomScale}px)`;
  }

  mapPanningEnded() {
    this.panningState.oldX = 0;
    this.panningState.oldY = 0;
  }

  getPinYDimension(pin: Pin) {
    const ci = this.currentImage.nativeElement.getBoundingClientRect();
    const ciw = this.currentImageWrapper.nativeElement.getBoundingClientRect();
    if (!this.yDimensionTimer || !this.pinYDimensionData) {
      this.yDimensionTimer = setTimeout(() => {
        // tslint:disable-next-line:max-line-length
        this.pinYDimensionData = `calc(${(ciw.top - ci.top) * -1}px + ${ci.height * (pin.y / 100)}px - 71px)`;
      }, 150);
    } else {
      // tslint:disable-next-line:max-line-length
      this.pinYDimensionData = `calc(${(ciw.top - ci.top) * -1}px + ${ci.height * (pin.y / 100)}px - 71px)`;
    }
    return this.pinYDimensionData;
  }

  getPinXDimension(pin: Pin) {
    const ci = this.currentImage.nativeElement.getBoundingClientRect();
    const ciw = this.currentImageWrapper.nativeElement.getBoundingClientRect();
    if (!this.xDimensionTimer || !this.pinXDimensionData) {
      // @ts-ignore
      this.xDimensionTimer = setTimeout(() => {
        // tslint:disable-next-line:max-line-length
        this.pinXDimensionData = `calc(${(ciw.left - ci.left) * -1}px + ${ci.width * (pin.x / 100)}px - 32.5px)`;
      }, 150);
    } else {
      // tslint:disable-next-line:max-line-length
      this.pinXDimensionData = `calc(${(ciw.left - ci.left) * -1}px + ${ci.width * (pin.x / 100)}px - 32.5px)`;
    }
    return this.pinXDimensionData;
  }

  onMapClicked(mouseEvent: MouseEvent) {
    const oldState = {...this.currentMapState};
    if (this.mapOperationMode === MapOperationMode.VIEW || this.mapOperationMode === MapOperationMode.EDIT || (this.mapOperationMode !== MapOperationMode.STRICT_CREATE && this.isPinDropped)) {
      return;
    }
    this.refreshStates();
    const x1 = this.currentImage.nativeElement.getBoundingClientRect().width;
    const y1 = this.currentImage.nativeElement.getBoundingClientRect().height;
    const mobImageX = this.currentImage.nativeElement.getBoundingClientRect().x;
    const mobImageY = this.currentImage.nativeElement.getBoundingClientRect().y;
    this.currentMapState = oldState;
    this.performZoomPanOperation();
    const x2 = this.currentImage.nativeElement.getBoundingClientRect().width;
    const y2 = this.currentImage.nativeElement.getBoundingClientRect().height;
    const xOffset = (x2 - x1) / 2;
    const yOffset = (y2 - y1) / 2;
    const dispX = (((mouseEvent.clientX + xOffset) - this.currentMapState.translateX) - mobImageX - 2); // -2 for UI fix
    const dispY = (((mouseEvent.clientY + yOffset) - this.currentMapState.translateY) - mobImageY + 5); // +5 for UI fix
    if (this.mapOperationMode === MapOperationMode.STRICT_CREATE) {
      for (const map of this.maps) {
        map.pins = [];
        // since mode is strict,
        // we need to first clear all the pins, then add pin to the clicked location
      }
      this.allPinsRemoved.emit('removed');
    }
    this.maps[this.currentMapIndex].pins = [...(this.maps[this.currentMapIndex].pins || []), {
      x: ((dispX / this.currentImage.nativeElement.getBoundingClientRect().width) * 100),
      y: ((dispY / this.currentImage.nativeElement.getBoundingClientRect().height) * 100),
      type: this.allowedPinColors[this.dropPinType],
      title: this.maps[this.currentMapIndex].defaultTitle,
      comment: this.maps[this.currentMapIndex].defaultComment,
    }];
    this.isPinDropped = true;
  }

  onDropPinTypeSelected(type: number) {
    this.dropPinType = type;
    if (!this.maps[this.currentMapIndex].pins || !this.maps[this.currentMapIndex].pins.length) {
      return;
    }
    this.maps[this.currentMapIndex].pins.forEach(pin => {
      if (pin.isSelected) {
        pin.type = this.allowedPinColors[type];
      }
    });

  }

  onPinSelected(pinIndex: number) {
    this.maps[this.currentMapIndex].pins.forEach((pin, index) => {
      if (index === pinIndex) {
        pin.isSelected = !pin.isSelected;
      } else {
        pin.isSelected = false;
      }
    });

  }

  onPinUpdated($event: Pin, pin: Pin) {
    pin = $event;
  }

  pinDragEnded(displacement, pin: Pin) {
    if (this.mapOperationMode === MapOperationMode.VIEW) {
      return;
    }
    this.isPinDragging = false;
    const newX = pin.x + (((displacement.x) / this.currentImage.nativeElement.getBoundingClientRect().width) * 100);
    const newY = pin.y + (((displacement.y) / this.currentImage.nativeElement.getBoundingClientRect().height) * 100);
    if (newX < 0 || newX > 100 || newY < 0 || newY > 100) {
      this._alert.error("CANNOT PLACE PIN OUTSIDE MAP AREA");
    } else {
      pin.x = newX;
      pin.y = newY;
    }
  }


  pinDragStarted(a?: any) {
    this.isPinDragging = true;
  }

  hideGhostElement($event: DragEvent) {
    const img = new Image();
    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
    $event.dataTransfer.setDragImage(img, 0, 0);
  }

  isPotrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  private loadAndRenderCurrentMap() {
    this.isCurrentMapDownloaded = false;
    this.currentImage.nativeElement.onload = () => {
      this.isCurrentMapDownloaded = true;
      if (this.isPotrait()) {
        this.currentMapState.zoomScale = 3;
        this.performZoomPanOperation();
      }
    };
    this.currentImage.nativeElement.src = this.currentMap.mapImageUrl;
  }

  private verifyInputData(): boolean {
    let isDataCorrect = true;
    const dataVerificationState = {};
    for (const map of this.maps) {
      if (dataVerificationState[map.uId]) {
        isDataCorrect = false;
        break;
      } else {
        dataVerificationState[map.uId] = true;
      }
    }
    return isDataCorrect;
  }
}

export interface MapData {
  mapImageUrl: string;
  uId: number;
  defaultTitle?: string;
  defaultComment?: string;
  pins?: Pin[];
}

export interface Pin {
  x: number;
  y: number;
  type: string;
  isSelected?: boolean; // majorly for internal uses.
  title: string;
  comment?: string;
}

export enum MapOperationMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create", // can have on pin per map, max
  STRICT_CREATE = "strict_create" // ensure only one pin across all maps
}
