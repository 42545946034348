import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

export interface BroadcastEvent<T> {
  key: any;
  data?: T;
}

@Injectable({ providedIn: "root" })
export class BroadcasterService {
  private _eventBus: Subject<BroadcastEvent<any>>;

  constructor() {
    this._eventBus = new Subject<BroadcastEvent<any>>();
  }

  broadcast(key: BroadcasterEvents, data?: any) {
    console.log("[Broadcaster.broadcast]", key, data);
    this._eventBus.next({ key, data });
  }

  all(): Observable<BroadcastEvent<any>> {
    return this._eventBus.asObservable();
  }

  on<T>(key: BroadcasterEvents): Observable<BroadcastEvent<T>> {
    return this._eventBus.asObservable()
      .pipe(
        filter(event => event.key === key),
      );
  }

  include<T>(keys: BroadcasterEvents[]): Observable<BroadcastEvent<T>> {
    return this._eventBus.asObservable()
      .pipe(
        filter(event => _.includes(keys, event.key)),
      );
  }
}

export enum Events {
  UPLOAD = "upload_started",
  UPLOAD_COMPETE = "upload_complete",
}

export type BroadcasterEvents = Events;
