import {Injectable} from "@angular/core";
import {ClientNameOnly} from "@emc-models/entities/client.model";
import {ProjectNameOnly} from "@emc-models/entities/project.model";
import {State} from "@emc-models/entities/state.model";
import {UserNameOnly} from "@emc-models/entities/user.model";
import {ApiService} from "@emc-modules/core/services/api.service";
import {Role} from "@emc-models/entities/role.model";
import {OptionsQuery} from "@emc-state/option/options.query";
import {OptionsStore} from "@emc-state/option/options.store";
import {Observable, of} from "rxjs";
import {filter, map, take} from "rxjs/operators";
import {ListResponse} from "../responses/list.response";
import {MarketCompact} from "@emc-models/entities/market.model";
import {SiteMap} from "@emc-models/entities/site-map.model";
import {ISelectOption} from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import {MailingListCompact} from "@emc-models/entities/mailing-list.model";

@Injectable()
export class OptionsService {


  constructor(private apiService: ApiService,
              private query: OptionsQuery,
              private store: OptionsStore) {
  }

  getUsers( showClient= false): Observable<UserNameOnly[]> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectUsersLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectUsersLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded)) {
      this.store.update({usersLoading: true, usersLoaded: false});
      this.apiService.get<ListResponse<UserNameOnly>>("options/users", true)
        .pipe(map(res => res.data))
        .subscribe(users => {
          this.store.update({
              users,
              usersLoaded: true,
              usersLoading: false
            });

        });
    }
    return this.query.$selectUsers(showClient).pipe(filter(s => !!s));
  }

  getStates(force = false): Observable<State[]> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectStatesLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectStatesLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({statesLoading: true, statesLoaded: false});
      this.apiService.get<ListResponse<State>>("options/states", true)
        .pipe(map(res => res.data))
        .subscribe(states => {
          this.store.update({
            states,
            statesLoaded: true,
            statesLoading: false
          });
        });
    }
    return this.query.$selectStates;
  }

  getClients(force = false): Observable<ClientNameOnly[]> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectClientsLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectClientsLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({clientsLoading: true, clientsLoaded: false});
      this.apiService.get<ListResponse<ClientNameOnly>>("options/clients", true)
        .pipe(map(res => res.data))
        .subscribe(clients => {
          this.store.update({
            clients,
            clientsLoaded: true,
            clientsLoading: false
          });
        });
    }
    return this.query.$selectClients;
  }

  getMarkets(force = false): Observable<MarketCompact[]> {

    let _loading = false;
    let _loaded = false;

    this.query
      .$selectMarketsLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectMarketsLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({marketsLoading: true, marketsLoaded: false});
      this.apiService.get<ListResponse<MarketCompact>>("options/markets", true)
        .pipe(map(res => res.data))
        .subscribe(markets => {
          this.store.update({
            markets,
            marketsLoaded: true,
            marketsLoading: false
          });
        });
    }
    return this.query.$selectMarkets;
  }

  getMailingList(force = false): Observable<MailingListCompact[]> {
    let _loading = false;
    let _loaded = false;

    this.query.$selectMailingListLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query.$selectMailingListLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({mailingListLoading: true, mailingListLoaded: false});
      this.apiService.get<ListResponse<MailingListCompact>>("options/mailing-lists", true)
        .pipe(map(res => res.data))
        .subscribe(mailingLists => {
          this.store.update({
            mailingLists,
            mailingListLoaded: true,
            mailingListLoading: false
          });
        });

    }
    return this.query.$selectMailingList;
  }

  getIsLoading(): Observable<boolean> {
    return this.query.$selectMailingListLoading;
  }


  getRoles(force = false): Observable<Role[]> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectRolesLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectRolesLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({rolesLoading: true, rolesLoaded: false});
      this.apiService.get<ListResponse<Role>>("options/roles", true)
        .pipe(map(res => res.data))
        .subscribe(roles => {
          this.store.update({
            roles,
            rolesLoaded: true,
            rolesLoading: false
          });
        });
    }
    return this.query.$selectRoles;
  }

  getProjects(force = false): Observable<ProjectNameOnly[]> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectProjectsLoading
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query
      .$selectProjectsLoaded
      .pipe(take(1))
      .subscribe(l => _loaded = l);


    if (!_loading && (!_loaded || force)) {
      this.store.update({projectsLoading: true, projectsLoaded: false});
      this.apiService.get<ListResponse<ProjectNameOnly>>("options/projects", true)
        .pipe(map(res => res.data))
        .subscribe(projects => {
          this.store.update({
            projects,
            projectsLoaded: true,
            projectsLoading: false
          });
        });
    }
    return this.query.$selectProjects;
  }

  getProjectOptionsForClientIds(ids: number[] = []): Observable<ISelectOption[]> {
    this.getProjects().pipe(take(1)).subscribe();

    if (!ids?.length) {
      return of([]);
    }

    return this.store._select(store => {
      return store.projects.filter(p => ids.findIndex(id => p.client_id === id) !== -1)
        .map(project => {
          return {
            title: project.title,
            value: project.id
          };
        });
    });
  }

  getClientOptionsForMarketId(id: number): Observable<ISelectOption[]> {
    this.getMarkets().pipe(take(1)).subscribe();

    if (!id) {
      return of([]);
    }

    return this.store._select(store => {
      return store.clients.filter(p => p.market_id === id)
        .map(client => {
          return {
            title: client.name,
            value: client.id
          };
        });
    });
  }

  getSiteMapsForProjectId(projectId: number, force?: boolean): Observable<SiteMap[]> {
    let loading: boolean;
    let loaded: boolean;

    this.query
      .$selectSiteMapsLoading
      .pipe(take(1))
      .subscribe(l => loading = l);

    this.query
      .$selectSiteMapsLoaded
      .pipe(take(1))
      .subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.update({siteMapsLoading: true, siteMapsLoaded: false});
      this.apiService.get<ListResponse<SiteMap>>("options/site-maps", true)
        .pipe(map(res => res.data))
        .subscribe(siteMaps => {
          this.store.update({
            siteMaps,
            siteMapsLoading: false,
            siteMapsLoaded: true
          });
        });
    }
    return this.query.$selectSiteMaps(projectId).pipe(filter(s => !!s));
  }

  getSiteMapsLoading(): Observable<boolean> {
    return this.query.$selectSiteMapsLoading;
  }

  getSiteMapsLoaded(): Observable<boolean> {
    return this.query.$selectSiteMapsLoaded;
  }


}
