import {Injectable} from "@angular/core";
import {ID, Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {MailingListFull} from "@emc-models/entities/mailing-list.model";
import {MailingListsState, MailingListsStore} from "@emc-state/tools/mailing-lists/mailing-lists.store";
import {CommonUtils} from "@emc-utils/common-utils";

@Injectable({
  providedIn: "root"
})
export class MailingListsQuery extends QueryEntity<MailingListsState, MailingListFull> {

  constructor(protected store: MailingListsStore) {
    super(store);
  }

  $selectIds = this.select("ids");
  $selectIsLoading = this.select("isLoading");
  $selectIsLoaded = this.select("isLoaded");
  $selectEntities = this.select("entities");

  $selectIsFullLoading = (id: ID) => this.select(state => state.fullLoadingIds.indexOf(id) !== -1);
  $selectIsFullLoaded = (id: ID) => this.select(state => state.fullLoadedIds.indexOf(id) !== -1);

  getMailingLists() {
    return combineLatest(this.$selectIds, this.$selectEntities)
      .pipe(map(([ids, entities]) => {
        const mailingLists = ids.filter(id => !!entities[id]).map(id => entities[id]);
        return CommonUtils.sortByKey(mailingLists, "title");
      }));
  }
}
