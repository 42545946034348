import { HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { BroadcasterService, Events } from "./broadcaster.service";
import { UploadResponse } from "@emc-models/responses/upload-response";
import { SignedUrlResponse } from "../../../responses/signed-url.response";
import { concatMap, map } from "rxjs/operators";
import { SignedUrlDto } from "@emc-models/requests/signed-url.dto";

@Injectable()
export class UploadService {

  constructor(private apiService: ApiService, private broadcaster: BroadcasterService) {
  }

  getSignedUrl(data: SignedUrlDto): Observable<string> {
    return this.apiService.post<SignedUrlResponse>("signed-url", true, data)
      .pipe(map(r => r.signed_url));
  }

  uploadToS3(directory: string, file: File): Observable<UploadResponse> {
    let signedUrl: string;
    return this.getSignedUrl({
      file_name: file.name,
      directory,
      content_type: file.type
    }).pipe(concatMap(sUrl => {
      signedUrl = sUrl;
      return this.upload(signedUrl, null, file);
    })).pipe(map((res: UploadResponse) => {
      if (res.is_complete) {
        res.data = signedUrl.replace("temp-upload/", "").split("?")[0];
      }
      return res;
    }));
  }


  upload(url: string, key: string, file: File, useBaseUrl = false): Observable<UploadResponse> {
    const subject$ = new Subject<UploadResponse>();
    this.apiService.upload(url, file, false, key, useBaseUrl).subscribe((res: any) => {
      if (res.type === HttpEventType.Response) {
        this.broadcaster.broadcast(Events.UPLOAD_COMPETE);
        setTimeout(() => {
          subject$.next({ progress: 100, is_complete: true, data: res.body });
          subject$.complete();
        }, 5000);
      } else if (res.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * res.loaded / res.total);
        this.broadcaster.broadcast(Events.UPLOAD, {
          progress,
        });
        subject$.next({ progress, is_complete: false });
      }
    }, (err) => {
      subject$.error(err);
    });

    return subject$.asObservable();
  }

}
