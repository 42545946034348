import { DrHortonComponent } from './modules/dashboard/components/dr-horton/dr-horton.component';
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {NotFoundComponent} from "@emc-modules/core/components/not-found/not-found.component";
import {AnonymousGuard} from "@emc-modules/core/guards/anonymous.guard";
import {AuthGuard} from "@emc-modules/core/guards/auth.guard";
import {SideNavContainerComponent} from "@emc-modules/shared/components/side-nav-conatiner/side-nav-container.component";
import {BootstrapComponent} from "@emc-modules/core/components/bootstrap/bootstrap.component";
import {BootstrapGuard} from "@emc-modules/core/guards/bootstrap.guard";
import {PermissionGuard} from "@emc-modules/core/guards/permission.guard";
import {PermissionType} from "./enums/permission-type.enum";
import {AppPath} from "./app.path";
import {MapEngineComponent} from "@emc-modules/shared/components/map-engine/map-engine.component";
import {DbResolver} from "@emc-modules/core/resolvers/db.resolver";
import {ToolsPath} from "@emc-modules/tools/tools.path";
import { StanleyMartinSafetyComponent } from './modules/dashboard/components/stanley-martin-safety/stanley-martin-safety.component';


const ROUTES: Routes = [
  {
    path: "map-engine",
    component: MapEngineComponent,
  },
  {
    path: "DRHortonCLTHERO",
    component: DrHortonComponent,
  },
  {
    path: "StanleyMartinSafety",
    component: StanleyMartinSafetyComponent,
  },
  {
    path: "",
    component: BootstrapComponent,
    canActivate: [BootstrapGuard],
    resolve: { db: DbResolver },
  },

  {
    path: AppPath.auth,
    loadChildren: () => import("./modules/auth/auth.module").then(m => m.AuthModule),
    canActivate: [AnonymousGuard]
  },
  {
    path: AppPath.dashboard,
    component: SideNavContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: AppPath.home
      },
      {
        path: AppPath.home,
        loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_VIEW]
        }
      },
      // {
      //   path: AppPath.inspections,
      //   loadChildren: () => import("./modules/inspections/inspections.module").then(m => m.InspectionsModule),
      //   canActivate: [PermissionGuard],
      //   data: {
      //     permissions: [PermissionType.DASHBOARD_INSPECTION_VIEW]
      //   },
      // },
      {
        path: AppPath.clients,
        loadChildren: () => import("./modules/clients/clients.module").then(m => m.ClientsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_CLIENT_VIEW]
        },
      },
      {
        path: AppPath.projects,
        loadChildren: () => import("./modules/projects/projects.module").then(m => m.ProjectsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_PROJECT_VIEW]
        },
      },
      {
        path: AppPath.rain_log,
        loadChildren: () => import("./modules/rain-log/rain-log.module").then(m => m.RainLogModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_PROJECT_VIEW]
        },
      },
      {
        path: AppPath.users,
        loadChildren: () => import("./modules/users/users.module").then(m => m.UsersModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_USER_VIEW]
        },
      },
      {
        path: AppPath.profile,
        loadChildren: () => import("./modules/profile/profile.module").then(m => m.ProfileModule)
      },
      {
        path: AppPath.tools,
        loadChildren: () => import("./modules/tools/tools.module").then(m => m.ToolsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_TOOL_VIEW]
        }
      },
      {
        path: `${AppPath.tools}/${ToolsPath.site_maps}`,
        loadChildren: () => import("./modules/tools/modules/site-maps/site-maps.module").then(m => m.SiteMapsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.TOOL_SITE_MAP_VIEW]
        },
      },
      {
        path: AppPath.trouble_ticket,
        loadChildren: () => import("./modules/tools/modules/trouble-tickets/trouble-tickets.module").then(m => m.TroubleTicketsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.TOOL_TROUBLE_TICKET_CREATE, PermissionType.TOOL_TROUBLE_TICKET_VIEW,
            PermissionType.TOOL_TROUBLE_TICKET_EDIT_CATEGORY_AND_URGENCY_LIST]
        }
      },
      {
        path: AppPath.admin_upload_reports,
        loadChildren: () => import("./modules/request-reports/modules/admin-upload-reports/admin-upload-reports.module").then(m => m.AdminUploadReportsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.INSPECTION_REPORT_VIEW, PermissionType.INSPECTION_REPORT_VIEW_ASSIGNED]
        }
      },
      {
        path: AppPath.project_activity_reports,
        loadChildren: () => import("./modules/request-reports/modules/project-activity-reports/project-activity-reports.module").then(m => m.ProjectActivityReportsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.TOOL_PROJECT_ACTIVITY_REPORT_VIEW]
        }
      },
      {
        path: AppPath.email_change,
        loadChildren: () => import("./modules/email-change/email-change.module").then(m => m.EmailChangeModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.TOOL_EMAIL_CHANGE_MODULE_VIEW]
        }
      },
      {
        path: AppPath.requests_reports,
        loadChildren: () => import("./modules/request-reports/request-reports.module").then(m => m.RequestReportsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.DASHBOARD_REQUEST_AND_REPORT_VIEW]
        }
      },
      {
        path: AppPath.leads,
        loadChildren: () => import("./modules/tools/modules/leads/leads.module").then(m => m.LeadsModule),
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.TOOL_LEADS_MODULE_VIEW]
        },
      },
      {
        path: AppPath.external_links,
        loadChildren: () => import("./modules/external-links/external-links.module").then(m => m.ExternalLinksModule),
      },
    ]
  },
  {
    path: "**",
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
