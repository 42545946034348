import { Injectable } from "@angular/core";
import { ApiService } from "@emc-modules/core/services/api.service";
import { Template } from "@emc-models/entities/template.model";
import { filter, map, take } from "rxjs/operators";
import { TemplatesQuery } from "@emc-state/tools/state-templates/template.query";
import { TemplatesStore } from "@emc-state/tools/state-templates/template.store";
import { Observable } from "rxjs";
import { ShowResponse } from "../../../../../responses/show.response";
import { ListResponse } from "../../../../../responses/list.response";
import { CommonUtils } from "@emc-utils/common-utils";
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})

export class TemplateService {

  constructor(private apiService: ApiService, private query: TemplatesQuery, private store: TemplatesStore) {

  }

  indexTemplates(): Observable<Template[]> {
    this.apiService.get<ListResponse<Template>>("templates", true)
      .pipe(map((res) => res.data))
      .subscribe((templates: Template[]) => {
        this.store.add(templates);
        this.store.update(state => ({
          loadedIds: _.uniq([...state.loadedIds, ...templates.map(t => t.id)])
        }));
      });

    return this.query.selectAll();
  }

  listClientTemplates(offset: number = 0): Observable<Template[]> {
    let _loading: boolean;
    let _loaded: boolean;
    let _offset = 0;
    let _data: any = { offset };

    this.query.selectClientOffset$
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.searchQuery$
      .pipe(take(1), filter(l => !!l))
      .subscribe(l => _data = { ..._data, query: l });

    this.query.selectClientLoading$
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query.selectClientLoaded$
      .pipe(take(1))
      .subscribe(l => _loaded = l);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({ clientLoading: true, clientLoaded: false });
      this.apiService.get<ListResponse<Template>>("templates", true, { ..._data, with_clients: true })
        .subscribe(res => {
          if (res.data && res.data.length) {
            const ids = res.data.map(m => m.id);
            const entities = CommonUtils.normalize(res.data);
            this.store.update(state => {
              return {
                clientLoading: false,
                clientOffset: state.offset + res.data.length,
                clientTemplateIds: _.uniq([
                  ...state.clientTemplateIds,
                  ...ids
                ]),
                entities: {
                  ...state.entities,
                  ...entities
                }
              };
            });
          } else {
            this.store.update({
              clientLoading: false,
              clientLoaded: true
            });
          }
        });
    }
    return this.query.selectClientTemplate();
  }

  listProjectTemplates(offset: number = 0): Observable<Template[]> {
    let _loading: boolean;
    let _loaded: boolean;
    let _offset = 0;
    let _data: any = { offset };

    this.query.selectProjectOffset$
      .pipe(take(1))
      .subscribe(v => _offset = v);

    this.query.searchQuery$
      .pipe(take(1), filter(l => !!l))
      .subscribe(l => _data = { ..._data, query: l });

    this.query.selectProjectLoading$
      .pipe(take(1))
      .subscribe(l => _loading = l);

    this.query.selectProjectLoaded$
      .pipe(take(1))
      .subscribe(l => _loaded = l);

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({ projectLoading: true, projectLoaded: false });
      this.apiService.get<ListResponse<Template>>("templates", true, { ..._data, with_projects: true })
        .subscribe(res => {
          if (res.data && res.data.length) {
            const ids = res.data.map(m => m.id);
            const entities = CommonUtils.normalize(res.data);
            this.store.update(state => {
              return {
                projectLoading: false,
                projectOffset: state.offset + res.data.length,
                projectTemplateIds: _.uniq([
                  ...state.projectTemplateIds,
                  ...ids
                ]),
                entities: {
                  ...state.entities,
                  ...entities
                }
              };
            });
          } else {
            this.store.update({
              projectLoading: false,
              projectLoaded: true
            });
          }
        });
    }
    return this.query.selectProjectTemplate();
  }

  getTemplateForId(id: number): Observable<Template> {
    let _loaded = false;
    let _loading = false;

    this.query.isIdLoaded$(id).pipe(take(1)).subscribe(l => _loaded = l);
    this.query.isIdLoading$(id).pipe(take(1)).subscribe(l => _loading = l);

    if (!_loaded && !_loading) {
      this.store.setIdLoading(id);
      this.apiService.get<ShowResponse<Template>>(`templates/${id}`, true)
        .pipe(map((res) => res.data))
        .subscribe((template: Template) => {
          this.store.setIdLoaded(id);
          this.store.add(template);
        }, (error) => {
          this.store.removeIdLoading(id);
        });
    }

    return this.query.selectEntity(id).pipe(filter((template: Template) => !!template));
  }

  getTemplateForState(stateId: number): Observable<Template> {
    return this.apiService.get<ShowResponse<Template>>(`states/${stateId}/template`, true)
      .pipe(map(res => res.data));
  }

  getSafetyStandardTemplate(): Observable<Template> {
    let loading: boolean;
    let loaded: boolean;

    this.safetyStandardTemplateLoading().pipe(take(1)).subscribe(l => loading = l);
    this.safetyStandardTemplateLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.update({ safetyStandardTemplateLoading: true, safetyStandardTemplateLoaded: false });
      this.apiService.get<ShowResponse<Template>>("templates/standard-safety-inspection", true)
        .subscribe(res => {
          this.store.update({
            safetyStandardTemplateLoading: false,
            safetyStandardTemplateLoaded: true,
            safetyStandardTemplate: res.data
          });
        });
    }
    return this.query.selectSafetyStandardTemplate$.pipe(filter(t => !!t));
  }

  safetyStandardTemplateLoading(): Observable<boolean> {
    return this.query.selectSafetyStandardTemplateLoading$;
  }

  safetyStandardTemplateLoaded(): Observable<boolean> {
    return this.query.selectSafetyStandardTemplateLoaded$;
  }

  createTemplate(data: any): Observable<Template> {

    return this.apiService.post<ShowResponse<Template>>("templates", true, data)
      .pipe(map(res => {
        this.store.add(res.data);
        return res.data;
      }));
  }

  updateTemplate(templateId: number, data: any): Observable<Template> {
    return this.apiService.put<ShowResponse<Template>>(`templates/${templateId}`, true, data)
      .pipe(map(res => {
        this.store.upsert(templateId, res.data);
        return res.data;
      }));
  }

  deleteTemplate(templateId: number): Observable<void> {
    return this.apiService.delete<void>(`templates/${templateId}`, true)
      .pipe(map(res => {
        this.store.remove(templateId);
        return res;
      }));
  }

  // setFilters(data: { search_query?: string }) {
  //   this.store.reset();
  //   this.store.update({
  //     query: data.search_query
  //   });
  // }

  deleteQuestionSuggestion(questionId: number, templateId: number): Observable<void> {
    return this.apiService.delete<void>(`question-suggestions/${questionId}`, true)
      .pipe(map(res => {
        this.store.update(templateId, template => {
          const index = (template.question_suggestions || []).findIndex(q => q.id === questionId);
          if (index !== -1) {
            return {
              question_suggestions: [
                ...template.question_suggestions.slice(0, index),
                ...template.question_suggestions.slice(index + 1)
              ]
            };
          }
          return template;
        });
        return res;
      }));
  }

}
