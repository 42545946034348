import { QueryEntity } from "@datorama/akita";
import { OptionsState, OptionsStore } from "@emc-state/option/options.store";
import { Injectable } from "@angular/core";
import { SiteMap } from "@emc-models/entities/site-map.model";
import {Observable} from "rxjs";
import {UserFull} from "@emc-models/entities/user.model";

@Injectable({ providedIn: "root" })
export class OptionsQuery extends QueryEntity<OptionsState> {

  // $selectUsers = this.select("users");
  $selectUsersLoading = this.select("usersLoading");
  $selectUsersLoaded = this.select("usersLoaded");

  $selectStates = this.select("states");
  $selectStatesLoading = this.select("statesLoading");
  $selectStatesLoaded = this.select("statesLoaded");

  $selectRoles = this.select("roles");
  $selectRolesLoading = this.select("rolesLoading");
  $selectRolesLoaded = this.select("rolesLoaded");

  $selectClients = this.select("clients");
  $selectClientsLoading = this.select("clientsLoading");
  $selectClientsLoaded = this.select("clientsLoaded");

  $selectProjects = this.select("projects");
  $selectProjectsLoading = this.select("projectsLoading");
  $selectProjectsLoaded = this.select("projectsLoaded");

  $selectMarkets = this.select(store => store.markets.filter(m => m.is_active));
  $selectMarketsLoading = this.select("marketsLoading");
  $selectMarketsLoaded = this.select("marketsLoaded");

  $selectSiteMapsLoading = this.select("siteMapsLoading");
  $selectSiteMapsLoaded = this.select("siteMapsLoaded");

  $selectMailingList = this.select("mailingLists");
  $selectMailingListLoading = this.select("mailingListLoading");
  $selectMailingListLoaded = this.select("mailingListLoaded");

  constructor(protected store: OptionsStore) {
    super(store);

  }

  $selectSiteMaps = (projectId: number) => this.select(store => {
    return store.siteMaps.filter((s: SiteMap) => {
      return s.project_id === projectId;
    });
  })

  $selectUsers = (showClients = false) => this.select(store => {
    const users = store.users;

    if (!showClients) {
      return users.filter(u => !u.is_client);
    }
    return users;
  })

  
}
