import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Template } from "@emc-models/entities/template.model";

export interface StateTemplatesState extends EntityState<Template> {
  loadedIds: number[];
  loadingIds: number[];
  stateOffset: number;
  stateLimit?: number;
  clientOffset: number;
  clientLimit?: number;
  projectOffset: number;
  projectLimit?: number;
  stateLoading: boolean;
  stateLoaded: boolean;
  clientLoading: boolean;
  clientLoaded: boolean;
  projectLoading: boolean;
  projectLoaded: boolean;
  query: string;
  clientTemplateIds: number[];
  projectTemplateIds: number[];
  stateTemplateIds: number[];
  safetyStandardTemplateLoading: boolean;
  safetyStandardTemplateLoaded: boolean;
  safetyStandardTemplate?: Template;
}

const initialState: StateTemplatesState = {
  loadedIds: [],
  loadingIds: [],
  stateOffset: 0,
  stateLimit: 10,
  clientOffset: 0,
  clientLimit: 10,
  projectOffset: 0,
  projectLimit: 10,
  stateLoading: false,
  stateLoaded: false,
  clientLoading: false,
  clientLoaded: false,
  projectLoading: false,
  projectLoaded: false,
  query: null,
  clientTemplateIds: [],
  projectTemplateIds: [],
  stateTemplateIds: [],
  safetyStandardTemplateLoading: false,
  safetyStandardTemplateLoaded: false,
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "templates" })
export class TemplatesStore extends EntityStore<StateTemplatesState, Template> {

  constructor() {
    super(initialState);
  }

  setIdLoading(id: number) {
    this.update(state => {
      let loadingIds = state.loadingIds;
      if (loadingIds.indexOf(id) === -1) {
        loadingIds = [...loadingIds, id];
      }

      return {
        loadingIds
      };
    });
  }

  removeIdLoading(id: number) {
    this.update(state => {
      const loadingIds = state.loadingIds.filter(i => i !== id);
      return {
        loadingIds
      };
    });
  }

  setIdLoaded(id: number) {
    this.update(state => {
      let loadedIds = state.loadedIds;
      if (loadedIds.indexOf(id) === -1) {
        loadedIds = [...loadedIds, id];
      }

      let loadingIds = state.loadingIds;
      const loadingIndex = loadingIds.indexOf(id);
      if (loadingIndex !== -1) {
        loadingIds = [...loadingIds.slice(0, loadingIndex), ...loadingIds.slice(loadingIndex + 1)];
      }
      return {
        loadedIds,
        loadingIds
      };
    });
  }

}

