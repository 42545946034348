import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import { IBreadcrumb } from "@emc-modules/shared/interfaces/breadcrumb.interface";
import { AppPath, AppTitle } from "../../../../../../../app.path";
import { ToolsPath, ToolsTitle } from "@emc-modules/tools/tools.path";
import { TemplateService } from "@emc-modules/tools/modules/templates/services/template.service";
import { ActivatedRoute, Router } from "@angular/router";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Template } from "@emc-models/entities/template.model";
import { State } from "@emc-models/entities/state.model";
import { OptionsService } from "@emc-services/options.service";
import { takeUntil } from "rxjs/operators";
import { Unsubscribe } from "../../../../../../../unsubscribe";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../../../../enums/permission-type.enum";
import {QuestionType} from "../../../../../../../enums/question-type.enum";


@Component({
  selector: "ae-view-template",
  templateUrl: "./view-template.component.html",
  styleUrls: ["./view-template.component.scss"],
  animations: [
    trigger("slideInOut", [
      state("collapsed", style({ maxHeight: "0px", padding: "0px 20px" })),
      state("expanded", style({ maxHeight: "700px", padding: "20px 20px", overflowY: "scroll" })),
      transition("* => *", animate("300ms ease-out")),
    ])
  ]
})

export class ViewTemplateComponent extends Unsubscribe implements OnInit {

  breadcrumbs: IBreadcrumb[] = [
    {
      url: AppPath.dashboard,
      label: AppTitle[AppPath.dashboard]
    },
    {
      url: AppPath.tools,
      label: AppTitle[AppPath.tools]
    },
    {
      url: ToolsPath.templates,
      label: ToolsTitle[ToolsPath.templates]
    },
    {
      url: "",
      label: ""
    }
  ];

  isStateTemplate: boolean;
  isLoading: boolean;
  template: Template;
  isPanelOpen = {};
  state: State;
  stateId: number;
  @Input() templateId: number;
  @Input() isDialog = false;
  @Output() closeDialog = new EventEmitter();

  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  QuestionType = QuestionType;

  constructor(private templateService: TemplateService,
              private router: Router,
              private optionsService: OptionsService,
              private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.isStateTemplate = this.activatedRoute.snapshot.data.isState;
    this.isStateTemplate ? this.setUpStateTemplate() : this.setUpTemplate();
  }

  setUpStateTemplate() {
    this.stateId = +this.activatedRoute.snapshot.params.stateId;
    this.isLoading = true;
    this.templateService.getTemplateForState(this.stateId).subscribe(template => {
      this.template = template;
      this.isLoading = false;
    });

    this.optionsService.getStates()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(states => {
        const selectedState = states.find(s => s.id === this.stateId);
        this.state = selectedState;
        if (selectedState) {
          this.breadcrumbs.pop();
          this.breadcrumbs.push({
            url: "",
            label: selectedState.title
          });
        }
      });
  }

  setUpTemplate() {
    if (!this.templateId) {
      this.templateId = +this.activatedRoute.snapshot.params.id;
    }

    this.isLoading = true;
    this.templateService.getTemplateForId(this.templateId).subscribe(template => {
      this.template = template;
      this.breadcrumbs.pop();
      this.breadcrumbs.push({
        url: "",
        label: template.title
      });
      this.isLoading = false;
    });
  }

  togglePanel(id) {
    if (this.isPanelOpen.hasOwnProperty(id)) {
      this.isPanelOpen[id] = !this.isPanelOpen[id];
      return;
    }

    this.isPanelOpen[id] = true;
  }

  edit() {
    this.router.navigate(["/dashboard/tools/templates", this.template.id, "edit"]);
  }

  OnClick() {
    this.closeDialog.emit();
  }
}
