import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ae-response-action",
  templateUrl: "./response-action.component.html",
  styleUrls: ["./response-action.component.scss"]
})

export class ResponseActionComponent {

  @Input() group: FormGroup;
  @Output() delete = new EventEmitter();

  onDelete() {
    this.delete.emit();
  }

}
