import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ClientFull } from "@emc-models/entities/client.model";
import { ProjectFull } from "@emc-models/entities/project.model";

@Component({
  selector: "ae-add-edit-container",
  templateUrl: "./add-edit-container.component.html",
  styleUrls: ["./add-edit-container.component.scss"]
})
export class AddEditContainerComponent {

  templateId: number;
  client: ClientFull;
  project: ProjectFull;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<AddEditContainerComponent>) {
    if (data) {
      this.templateId = data.templateId;
      this.client = data.client;
      this.project = data.project;
    }


  }

  close() {
    this.dialogRef.close();
  }
}
