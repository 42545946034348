import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { IBreadcrumb } from "@emc-modules/shared/interfaces/breadcrumb.interface";

@Component({
  selector: "ae-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"]
})
export class BreadcrumbComponent {

  @Input() breadcrumbs: IBreadcrumb[] = [];
  constructor(private router: Router) {
  }

  onClick(index: number) {
    const redirectArray = this.breadcrumbs.slice(0, index + 1).map(breadcrumb => breadcrumb.url);
    this.router.navigate(redirectArray);
  }

}
