<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>Update All Actions</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="body">
    <form (ngSubmit)="updateAllActions()" ngForm>
      <div class="elements">
        <p class="label">Responsive Actions</p>
        <ae-search-field
            [control]="controls.responsive_actions"
            [multiple]=true
            [options]="actionSelectOptions"
            placeholder="Responsive Actions"></ae-search-field>
      </div>
      <ng-container *ngIf="hasSignOffPermission">
        <div class="elements">
          <p class="label">Completed By</p>
          <ae-search-field
              [control]="controls.completed_by"
              [options]="userOptions" placeholder="Completed By"></ae-search-field>
        </div>
        <div class="elements">
          <p class="label">Completed On</p>
          <mat-form-field floatLabel="never">
            <input (focus)="startpicker.open()" [formControl]="controls.completed_on"
                   [matDatepicker]="startpicker"
                   matInput
                   placeholder="Completed On" type="text">
            <mat-datepicker-toggle [for]="startpicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="buttons">
        <button (click)="close()" type="button" color="warn" mat-flat-button>Cancel</button>
        <div class="spacer"></div>
        <button *ngIf="!updating" color="primary" mat-flat-button type="submit">Update</button>
        <mat-spinner *ngIf="updating" diameter="20" strokeWidth="2"></mat-spinner>
      </div>
    </form>
  </div>
</div>
