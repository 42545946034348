import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ae-section-container",
  templateUrl: "./section-container.component.html",
  styleUrls: ["./section-container.component.scss"]
})
export class SectionContainerComponent implements OnInit {

  @Input() title: string;
  @Input() showButton: boolean;
  @Input() buttonText: string;
  @Output() onButtonClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onClick() {
    this.onButtonClick.emit();
  }

}
