import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import SignaturePad from "signature_pad";
import { CanvasResizer } from "@emc-utils/canvas-resizer";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "ae-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SignatureComponent,
    multi: true
  }]
})
export class SignatureComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild("signaturePad") canvas: ElementRef<HTMLCanvasElement>;
  signaturePad: SignaturePad;
  canvasResizer: CanvasResizer;
  hasValue = false;
  public value: string;
  disabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    this.canvasResizer = new CanvasResizer(this.canvas.nativeElement);
    window.onresize = this.resize.bind(this);
    this.signaturePad.onEnd = () => {
      this.value = this.signaturePad.toDataURL("image/svg+xml");
      this.onChange(this.value);
      this.onTouch();
    };

    if (this.value) {
      this.signaturePad.fromDataURL(this.value, {}, (err) => console.log(err));
      this.setDisabledState(true);
    }
  }

  resize() {
    if (this.canvasResizer) {
      this.canvasResizer.resize();
    }
    if (this.value && this.signaturePad) {
      this.signaturePad.fromDataURL(this.value);
    }
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.hasValue = false;
      this.setDisabledState(false);
      this.onChange(null);
      this.onTouch();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.signaturePad) {
      this.signaturePad.fromDataURL(value);
    }
    if (this.value) {
      this.hasValue = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.signaturePad) {
      isDisabled ? this.signaturePad.off() : this.signaturePad.on();
    }
  }

  private onChange = (value: string) => {
    // Comment
  }

  private onTouch = () => {
  }

}
