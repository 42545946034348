<div [formGroup]="group" class="emc-box">
  <div class="head">
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      {{group.controls.sort_order.value}}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="changeSortOrder(option - 1)" *ngFor="let option of sortOptions" mat-menu-item>
        {{option}}
      </button>
    </mat-menu>
    <div class="buttons">
      <mat-icon (click)="cloneQuestionSuggestion()" color="primary" matTooltip="Clone">file_copy</mat-icon>
      <mat-icon (click)="deleteQuestionSuggestion()" color="warn" matTooltip="Delete">delete</mat-icon>
    </div>
  </div>
  <div class="elements" *ngIf="!group?.get('blank_question')?.value">
    <p aeMarkAsterisk class="label">Question</p>
    <mat-form-field class="question" floatLabel="never">
      <input formControlName="title" matInput placeholder="Question" type="text">
    </mat-form-field>
  </div>
  <div class="elements">
    <p aeMarkAsterisk class="label">Category</p>
    <mat-form-field *ngIf="!categoriesLoading" floatLabel="never">
      <mat-select formControlName="category_id" placeholder="Question Category">
        <mat-option *ngFor="let category of questionCategories" [value]="category.id">
          {{category.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="categoriesLoading">
      <div class="center-spinner">
        <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
      </div>
    </ng-container>
  </div>
  <div class="elements">
    <p class="label"></p>
    <div class="checkbox">
      <mat-checkbox color="primary" formControlName="is_active">Active</mat-checkbox>
      <mat-checkbox color="primary" formControlName="has_sub_questions">Sub Questions</mat-checkbox>
      <mat-checkbox color="primary" formControlName="show_assigned_to">Show Assigned To</mat-checkbox>
      <mat-checkbox color="primary" (change)="(!!$event.checked && !group?.get('options')?.length) ? addOption() : ''" formControlName="required_root_response">Requires Root Question Response
      </mat-checkbox>
      <ng-container *ngIf="_type === QuestionType.PULTE">
        <mat-checkbox color="primary" formControlName="blank_question">Blank Question
        </mat-checkbox>
      </ng-container>
    </div>
  </div>

  <div class="elements">
    <p class="label">Options</p>
    <div class="sub-element">
      <button (click)="addOption()" class="secondary" mat-button>Add Option</button>
    </div>
  </div>

  <div class="responsive-action-container">
    <ae-option-card (delete)="deleteOption(i)"
                    *ngFor="let option of group?.get('options')?.controls;let i =index"
                    [group]="option">
    </ae-option-card>
  </div>

  <div class="elements">
    <p class="label">Default Option</p>
    <mat-form-field floatLabel="never">
      <mat-select [formControl]="group?.get('default_option')" placeholder="Default Option">
        <mat-option *ngFor="let option of (group?.get('options').value || [])" [value]="option.title">
          {{option.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="elements">
    <p class="label">Responsive Actions</p>
    <div class="sub-element">
      <button (click)="addResponsiveItem()" class="secondary" mat-button>Add Responsive Action</button>
      <b (click)="isPanelOpen = !isPanelOpen" *ngIf="isPanelOpen">Collapse</b>
      <b (click)="isPanelOpen = !isPanelOpen" *ngIf="!isPanelOpen">Expand</b>
    </div>
  </div>
  <div *ngIf="isPanelOpen" class="responsive-action-container">
    <ae-response-action (delete)="deleteResponsiveItem(i)"
                        *ngFor="let responsiveGroup of group?.get('responsive_action_item_suggestions')?.controls;let i =index"
                        [group]="responsiveGroup">
    </ae-response-action>
  </div>
  <div *ngIf="group.get('has_sub_questions')?.value" class="elements">
    <p class="label">Sub Questions</p>
    <div class="sub-element">
      <button (click)="addSubQuestion()" class="secondary" mat-button>Add Sub Question</button>
    </div>
  </div>

  <div *ngIf="group.get('has_sub_questions')?.value" class="responsive-action-container">
    <ae-sub-question-card (delete)="deleteSubQuestion(i)"
                          *ngFor="let responsiveGroup of group?.get('sub_questions')?.controls;let i =index"
                          [group]="responsiveGroup"
                          [templateId]="templateId">
    </ae-sub-question-card>
  </div>
</div>
