<div class="container" aeSizeClass>
  <ng-container *ngIf="!isDataLoading">
    <router-outlet></router-outlet>
  </ng-container>
  <div *ngIf="isDataLoading" class="full-screen-mat-spinner">
    <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
  </div>
</div>


