import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Unsubscribe } from "../../../../unsubscribe";
import { DialogService } from "@emc-modules/shared/services/dialog.service";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../enums/permission-type.enum";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { OptionsService } from "@emc-services/options.service";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { RainLog } from "../../../../models/entities/rain-log.model";
import { RainLogService } from "../../services/rain-log.service";
import { Router } from "@angular/router";
import { FormControl, Validators, FormArray, FormGroup } from "@angular/forms";
import { CommonUtils } from "../../../../utils/common-utils";
import * as moment from "moment";

@Component({
  selector: "ae-res-ac-item-card-dialog",
  templateUrl: "./rain-log-card-dialog.component.html",
  styleUrls: ["./rain-log-card-dialog.component.scss"],
})
export class RainLogCardDialogComponent extends Unsubscribe implements OnInit {
  title = "Rain Log ";
  projectId: number;
  clientId: number;
  items: RainLog[] = [];
  rainLogLoading: boolean;
  selectedItems: number[] = [];
  isAllSelected = false;
  userOptions: ISelectOption[] = [];
  updating = false;
  lastRainLogDate: string;
  hasSignOffPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_SIGN_OFF_RESPONSIVE_ACTION
  );
  hasAddEditPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_ADD_EDIT_RESPONSIVE_ACTION
  );
  saving: boolean;
  last7daysRainLog: RainLog[];
  index = 7;

  constructor(
    private dialogRef: MatDialogRef<RainLogCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      clientId: number;
      projectId: number;
    },
    private router: Router,
    private rainLogService: RainLogService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private authManager: AuthManager,
    private rainLogsService: RainLogService,
    private option: OptionsService
  ) {
    super();
    if (data) {
      this.title = data.title;
      this.projectId = data.projectId;
      this.clientId = data.clientId;
    }
  }

  controls = {
    rain_station: new FormControl(null, [Validators.required]),
    rain_data: new FormArray([]),
  };

  form = new FormGroup(this.controls);

  ngOnInit() {
    this.rainLogLoading = true;
    this.rainLogService
      .getLast7daysRainLog(this.projectId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((res) => {
        console.log(res);
        this.last7daysRainLog = res.data;
        this.lastRainLogDate = res.last_rain_log_date;
        this.form.patchValue({
          rain_station: res.project.rain_station,
        });

        this.initialiseRainData(this.last7daysRainLog, this.lastRainLogDate);
      });
    this.rainLogService;
  }

  private initialiseRainData(rainLog: RainLog[], lastRainLogDate: string) {
    const unFilledRainLogDateArray =
      CommonUtils.enumerateDaysBetweenDates(lastRainLogDate);
    console.log(unFilledRainLogDateArray);
    unFilledRainLogDateArray.map((date) => {
      const rainLogControl = {
        duration: new FormControl(0, [Validators.min(0), Validators.required]),
        quantity: new FormControl(0, [Validators.min(0), Validators.required]),
        date: new FormControl(date, [Validators.required]),
        disabled: new FormControl(false),
      };
      const rainLogGroup = new FormGroup(rainLogControl);
      this.controls.rain_data.push(rainLogGroup);
    });

    rainLog.forEach((rain) => {
      const rainLogControl = {
        id: new FormControl(rain.id),
        duration: new FormControl({ value: rain.duration, disabled: true }, [
          Validators.min(0),
          Validators.required,
        ]),
        quantity: new FormControl({ value: rain.quantity, disabled: true }, [
          Validators.min(0),
          Validators.required,
        ]),
        date: new FormControl(rain.date, [Validators.required]),
        disabled: new FormControl(true),
      };
      const rainLogGroup = new FormGroup(rainLogControl);
      this.controls.rain_data.push(rainLogGroup);
    });
    this.rainLogLoading = false;
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  getIsSelected(id) {
    return this.selectedItems.includes(id);
  }

  submit() {
    console.log(this.form, "form");

    if (this.form.invalid) {
      CommonUtils.markControlsAsTouched(this.form);
      return;
    }
    this.saving = true;

    const data = this.createRequestData(this.form.value);

    this.create(data);
  }

  createRequestData(formValue: any) {
    console.log(formValue, "formValue");
    let data = {
      rain_data: formValue.rain_data
        .slice(0, this.index)
        .filter((rainLog) => !rainLog?.id)
        .map((rainLog) => {
          return {
            duration: +rainLog.duration,
            quantity: +rainLog.quantity,
            date: moment(rainLog.date).format("YYYY-MM-DD"),
          };
        }),
      rain_station: formValue.rain_station,
      project_id: this.projectId,
      client_id: this.clientId,
    };
    return data;
  }

  expand(n: number) {
    this.index = this.form.value.rain_data.length;
  }

  create(data: any) {
    delete data.status;
    this.rainLogsService.bulkCreateRainLog(data).subscribe(
      () => {
        this.toastr.success("Rain Log Successfully Created");
        this.close();
        this.saving = false;
      },
      (e) => {
        this.saving = false;
        setTimeout(() => {
          for (const error of e?.error?.errors || []) {
            this.controls[error?.dataPath.substring(1)]?.setErrors(
              { validation: true },
              { emitEvent: true }
            );
          }
        });
      }
    );
  }
}
