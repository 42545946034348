import { Injectable } from "@angular/core";
import { UserFull } from "@emc-models/entities/user.model";
import { AuthService } from "@emc-modules/core/services/auth.service";
import { AppQuery } from "@emc-state/app/app.query";
import { AppStore } from "@emc-state/app/app.store";
import { AUTH_TOKEN } from "@emc-utils/constants";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { ApiService } from "@emc-modules/core/services/api.service";
import { SentryService } from "@emc-modules/sentry/sentry.service";

@Injectable()
export class AuthManager {

  constructor(private appQuery: AppQuery,
              private appStore: AppStore,
              private authService: AuthService,
              private sentryService: SentryService,
              private apiService: ApiService) {
  }

  hasAuthToken(): boolean {
    return !!localStorage.getItem(AUTH_TOKEN);
  }

  setAuthToken(token: string) {
    localStorage.setItem(AUTH_TOKEN, token);
  }

  isLoaded(): Observable<boolean> {
    return this.appQuery.select("isLoaded");
  }

  isLoading(): Observable<boolean> {
    return this.appQuery.select("isLoading");
  }

  isBootstrapped(): Observable<boolean> {
    return this.appQuery.select("bootstrapped");
  }

  setBootstrapped(bootstrapped: boolean) {
    this.appStore.update({ bootstrapped });
  }

  getReturnUrl() {
    return this.appQuery.select("returnUrl");
  }

  getLoggedInUser(force?: boolean): Observable<UserFull> {
    let loading = false;
    let loaded = false;
    this.isLoading()
      .pipe(take(1))
      .subscribe(l => loading = l);
    this.isLoaded()
      .pipe(take(1))
      .subscribe(l => loaded = l);
    if (!loading && (!loaded || force)) {
      this.fetchLoggedInUser();
    }
    return this.appQuery.select("user").pipe(filter(u => !!u));
  }

  setReturnUrl(url: string) {
    this.appStore.update({ returnUrl: url });
  }

  login(credentials: { email: string, password: string, remember_me: boolean }): Observable<UserFull> {
    return this.authService.login(credentials)
      .pipe(
        map(response => {
            this.appStore.update({
              user: response.user,
              isLoaded: true,
              isLoading: false
            });
            this.setAuthToken(response.token);
            PermissionUtils.setAssignedPermissions(response.user.role.permissions);
            return response.user;
          }
        ));
  }

  signup(data: any): Observable<UserFull> {
    return this.authService.signup(data);
  }

  forgotPassword(email: string): Observable<any> {
    return this.authService.forgotPassword(email);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.authService.resetPassword(token, password);
  }

  updateMe(data: any): Observable<UserFull> {
    return this.authService.updateLoggedInUser(data)
      .pipe(map(res => {
        this.appStore.update({
          user: res
        });
        return res;
      }));
  }

  updateProfilePic(url: string): Observable<UserFull> {
    return this.authService.uploadProfilePic(url)
      .pipe(map(res => {
        this.appStore.update({
          user: res
        });
        return res;
      }));
  }

  private fetchLoggedInUser() {
    this.authService.getLoggedInUser().subscribe(
      (data) => {
        PermissionUtils.setAssignedPermissions(data.role.permissions);
        this.sentryService.setUserContext(data);
        this.appStore.update({
          user: data,
          isLoaded: true,
          isLoading: false
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
