import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[aeTouchClick]"
})
export class TouchClickDirective implements OnInit {
  @Input() maxDistance = 100;
  @Input() maxTime = 2000;

  @Input() touchClick: boolean;
  start: Touch;

  constructor(private elem: ElementRef) {
    this.start = null;
  }

  ngOnInit(): void {
    // Bind the touches event to the element
    this.bindTouchEvents();
  }

  bindTouchEvents() {
    this.elem.nativeElement.addEventListener("touchstart", this.onTouchStart.bind(this), false);
    this.elem.nativeElement.addEventListener("touchend", this.onTouchEnd.bind(this), false);
  }

  onTouchStart(e: TouchEvent) {
    // hold the touch start position
    this.start = e.touches[0];

    // clear the position after 2000 mil (could be set for less).
    setTimeout(() => {
      this.start = null;
    }, this.maxTime);
  }

  onTouchEnd(e: TouchEvent) {
    // if the timeout was called, there will be no start position
    if (!this.start) {
      return;
    }

    // calculate the distance between start and end position
    const end = e.changedTouches[0];
    const dx = Math.pow(this.start.pageX - end.pageX, 2);
    const dy = Math.pow(this.start.pageY - end.pageY, 2);
    const distance = Math.round(Math.sqrt(dx + dy));

    // if the distance is fairly small, fire
    // a click event. (default is 20 but you can override it through the constructor)
    if (distance <= this.maxDistance) {
      this.elem.nativeElement.click();
    }

    // clear the start position again
    this.start = null;
  }
}
