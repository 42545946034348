import { Component, OnInit } from "@angular/core";
import { PermissionsService } from "@emc-state/permissions/permissions.service";
import { PermissionsQuery } from "@emc-state/permissions/permissions.query";
import { takeUntil } from "rxjs/operators";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { Unsubscribe } from "../../../../unsubscribe";
import { Router } from "@angular/router";
import { combineLatest } from "rxjs";

@Component({
  selector: "ae-bootstrap-component",
  templateUrl: "bootstrap.component.html",
  styleUrls: ["bootstrap.component.scss"]
})

export class BootstrapComponent extends Unsubscribe implements OnInit {

  loading: boolean;

  constructor(private permissionsService: PermissionsService,
              private authManager: AuthManager,
              private router: Router,
              private permissionsQuery: PermissionsQuery) {
    super();
  }

  ngOnInit() {
    this.permissionsQuery
      .selectLoading()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => this.loading = l);

    this.permissionsService
      .list().subscribe();

    this.authManager.getLoggedInUser()
      .subscribe();

    const bootstrap = combineLatest(this.permissionsQuery
        .selectLoaded$
        .pipe(takeUntil(this._destroyed$)),
      this.authManager.isLoaded(),
      (permissionLoaded, userLoaded) => {
        return {
          permissionLoaded,
          userLoaded
        };
      }
    );


    bootstrap
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: any) => {
        if (data.permissionLoaded && data.userLoaded) {
          this.authManager.setBootstrapped(true);
          this.authManager.getReturnUrl()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(url => this.router.navigateByUrl(url));
        }
      });

  }
}
