import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AlertService {
  constructor(private toastr: ToastrService) {
  }

  success(message: string, duration = 3500) {

    message = message.substring(0, 100);
    this.toastr.success(message, "Success");
  }

  error(message: string, duration = 3500) {
    console.error(message);
    message = message?.substring(0, 100);
    this.toastr.error(message, "Unsuccessful");
  }
}
