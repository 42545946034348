import { Unsubscribe } from "src/app/unsubscribe";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { InspectionsQuery } from "../../../../state/inspection/inspections.query";
import { skip, takeUntil } from "rxjs/operators";
import { InspectionsService } from "../../services/inspections.service";
import { Inspection } from "../../../../models/entities/inspection.model";
import * as moment from "moment";

@Component({
  selector: "ae-add-new-inspection",
  templateUrl: "./add-new-inspection.component.html",
  styleUrls: ["./add-new-inspection.component.scss"],
})
export class AddNewInspectionComponent extends Unsubscribe implements OnInit {
  controls = {
    addNew: new FormControl(null, [Validators.required]),
  };
  isLoading: boolean = false;
  form = new FormGroup(this.controls);
  inspectionId: number;
  inspection: Inspection;

  constructor(
    private dialogBox: MatDialogRef<AddNewInspectionComponent>,
    private toastr: ToastrService,
    private router: Router,
    private inspectionsService: InspectionsService,
    private inspectionsQuery: InspectionsQuery,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super();
    if (data) {
      this.inspectionId = data.inspectionId;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogBox.close();
  }

  submit() {
    const value = this.form.value.addNew;
    this.inspectionsQuery
      .$selectIsFullLoading(this.inspectionId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((l) => {
        this.isLoading = l;
      });

    this.inspectionsService
      .getInspectionById(this.inspectionId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((inspection) => {
        this.inspection = { ...inspection };
        this.inspection.inspection_date=moment().format("YYYY-MM-DD");
        this.inspection.inspection_time=moment().format('hh:mm a');
        if(this.inspection?.qaInspection?.qa_inspection_template_id){
          this.inspection.qaInspection.qa_inspection_template_id = null;
        }

        this.close();
        if (value === "storm-water") {
          this.inspection.questions = [];
          this.router.navigate(
            ["/dashboard/inspections/site-inspections/add"],
            { state: { inspection: this.inspection } }
          );
        } else if (value === "safety") {
          if (this.inspection.safetyInspection) {
            this.inspection.safetyInspection={
              ...this.inspection.safetyInspection,
              safetyObservations:[],
              questions:[],
              template:null
            }
          }
          this.router.navigate(
            ["/dashboard/inspections/safety-inspections/add"],
            { state: { inspection: this.inspection } }
          );
        } else if (value === "energy") {
          if (this.inspection.qaInspection) {
            (this.inspection.energyInspection as any) = {};
            this.inspection.comment=this.inspection.qaInspection.qa_1_comments;
            this.inspection.energyInspection.address =
              this.inspection.qaInspection.street_address;
            this.inspection.energyInspection.lot_number =
              this.inspection.qaInspection.home_site;
          }
          else{
            this.inspection.energyInspection = {
              address:this.inspection.energyInspection.address,
              lot_number:this.inspection.energyInspection.lot_number,
            } as any;

          }
          this.router.navigate(
            ["/dashboard/inspections/energy-inspections/add"],
            { state: { inspection: this.inspection } }
          );
        } else {
          if (this.inspection.qaInspection) {
            this.inspection.qaInspection = {
              qa1_comments:this.inspection.qaInspection.qa_1_comments,
              follow_up_reference_number:1,
              constructionManager:this.inspection.qaInspection.constructionManager,
              areaConstructionManager:this.inspection.qaInspection.areaConstructionManager,
              home_site: this.inspection.qaInspection.home_site,
              street_address: this.inspection.qaInspection.street_address,
            } as any;
            this.inspection.attachments = [];
        } else if (this.inspection.energyInspection) {
            (this.inspection.qaInspection as any) = {};
            this.inspection.qaInspection.follow_up_reference_number =1
            this.inspection.qaInspection.street_address =
              this.inspection.energyInspection.address;
              this.inspection.qaInspection.qa_1_comments =this.inspection.comment;
              this.inspection.qaInspection.home_site=this.inspection.energyInspection.lot_number;
          }
          this.router.navigate(["/dashboard/inspections/qa-inspections/add"], {
            state: { inspection: this.inspection },
          });
        }
      });
  }
}
