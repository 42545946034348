import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AdminUploadReport, UploadedReportType} from "@emc-models/entities/admin-upload-reports.model";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "src/app/enums/permission-type.enum";
import {Router} from "@angular/router";
import {DialogService} from "@emc-modules/shared/services/dialog.service";
import {ToastrService} from "ngx-toastr";
import {GenericConfirmationComponent} from "@emc-modules/shared/components/generic-confirmation/generic-confirmation.component";
import {AdminUploadReportService} from "@emc-modules/request-reports/modules/admin-upload-reports/services/admin-upload-report.service";
import {saveAs} from "file-saver/dist/FileSaver";
import { filter } from "rxjs/operators";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: "ae-admin-report",
  templateUrl: "./admin-report.component.html",
  styleUrls: ["./admin-report.component.scss"]
})
export class AdminReportComponent implements OnInit {

  @Input() adminReport: AdminUploadReport;
  @Input() typeOfService: string;
  @Input() selected = false;
  @Input() disabled = false;
  deleting: boolean;
  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  @Output() selectedChanged = new EventEmitter<boolean>();

  constructor(private router: Router,
              private dialog: DialogService,
              private toaster: ToastrService,
              private adminReportsService: AdminUploadReportService) { }

  ngOnInit() {
  }



  open(action: string) {
    this.router
      .navigate(["dashboard/upload-reports", this.adminReport.id, action]);
  }

  onChange(event: MatCheckboxChange) {
    this.selectedChanged.emit(event.checked);
  }

  delete() {
    this.dialog
      .open(GenericConfirmationComponent, { height: "200px" ,
        data: {title: ` Delete Upload Report`,
          description: `Are you sure you want to delete this Upload Report?`}})
      .afterClosed()
      .pipe(filter(d => !!d?.yesClicked))
      .subscribe(v => {

        if (v) {
          this.deleting = true;
          this.adminReportsService
            .deleteAdminReport(this.adminReport.id)
            .subscribe(() => {
              this.deleting = false;
              this.toaster.success("Upload Report Successfully Deleted");
            }, () => this.deleting = false);
        }
      });
  }

  downloadAttachments() {
     this.adminReport.attachments.forEach(attachment => {
       saveAs(attachment.attachment_url, attachment.title);
     });
  }
}
