import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { StateTemplatesState, TemplatesStore } from "@emc-state/tools/state-templates/template.store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { CommonUtils } from "@emc-utils/common-utils";

@Injectable({
  providedIn: "root"
})
export class TemplatesQuery extends QueryEntity<StateTemplatesState> {

  // state_template
  selectStateOffset$ = this.select("stateOffset");
  selectStateLimit$ = this.select("StateLimit");
  selectStateLoading$ = this.select("stateLoading");
  selectStateLoaded$ = this.select("StateLoaded");
  selectStateTemplateIds$ = this.select("stateTemplateIds");

  // client_template

  selectClientOffset$ = this.select("clientOffset");
  selectClientLimit$ = this.select("clientLimit");
  selectClientLoading$ = this.select("clientLoading");
  selectClientLoaded$ = this.select("clientLoaded");
  selectClientTemplateIds$ = this.select("clientTemplateIds");

  // project_template

  selectProjectOffset$ = this.select("projectOffset");
  selectProjectLimit$ = this.select("projectLimit");
  selectProjectLoading$ = this.select("projectLoading");
  selectProjectLoaded$ = this.select("projectLoaded");
  selectProjectTemplateIds$ = this.select("projectTemplateIds");

  selectSafetyStandardTemplateLoading$ = this.select("safetyStandardTemplateLoading");
  selectSafetyStandardTemplateLoaded$ = this.select("safetyStandardTemplateLoaded");
  selectSafetyStandardTemplate$ = this.select("safetyStandardTemplate");

  searchQuery$ = this.select("query");
  $entities = this.select("entities");

  constructor(protected store: TemplatesStore) {
    super(store);
  }

  isIdLoaded$ = (id: number) => this.select(state => state.loadedIds.indexOf(id) !== -1);

  isIdLoading$ = (id: number) => this.select(state => state.loadingIds.indexOf(id) !== -1);

  selectStateTemplate() {
    return combineLatest(this.selectStateTemplateIds$, this.$entities)
      .pipe(map(([ids, entities]) => {
        const stateTemplates = ids.filter(id => !!entities[id]).map(id => entities[id]);
        return CommonUtils.sortByKey(stateTemplates, "title");
      }));
  }

  selectClientTemplate() {
    return combineLatest(this.selectClientTemplateIds$, this.$entities)
      .pipe(map(([ids, entities]) => {
        const clientTemplates = ids.filter(id => !!entities[id]).map(id => entities[id]);
        return CommonUtils.sortByKey(clientTemplates, "title");
      }));
  }

  selectProjectTemplate() {
    return combineLatest(this.selectProjectTemplateIds$, this.$entities)
      .pipe(map(([ids, entities]) => {
        const projectTemplates = ids.filter(id => !!entities[id]).map(id => entities[id]);
        return CommonUtils.sortByKey(projectTemplates, "title");
      }));
  }

}
