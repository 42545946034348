export class CanvasResizer {

  private _canvas: HTMLCanvasElement;
  private _width: number = undefined;
  private _height: number = undefined;
  private _isDisposed = false;

  constructor(canvas: HTMLCanvasElement, highDefinition: boolean = true, autoResize: boolean = true) {
    this._canvas = canvas;
    this._highDefinition = highDefinition;
    this._autoResize = autoResize;

    this.window_resize = this.window_resize.bind(this);

    window.addEventListener("resize", this.window_resize, false);

    if (this._autoResize) {
      this.resize();
    }
  }

  private _contentScaleFactor = 1;

  public get contentScaleFactor(): number {
    return this._contentScaleFactor;
  }

  private _highDefinition = true;

  public get highDefinition(): boolean {
    return this._highDefinition;
  }

  public set highDefinition(value: boolean) {
    if (this._highDefinition === value) {
      return;
    }
    this._highDefinition = value;
    this.resize();
  }

  private _autoResize = true;

  public get autoResize(): boolean {
    return this._autoResize;
  }


  public set autoResize(value: boolean) {
    if (this._autoResize === value) {
      return;
    }
    this._autoResize = value;
    this.resize();
  }


  private static readViewportScale(): number {
    const viewports: any = document.querySelectorAll("meta[name=viewport]");
    let viewport: any = null;
    if (viewports.length > 0) {
      viewport = viewports[viewports.length - 1];
    }

    if (viewport === null) {
      return null;
    }

    const contentValue: string = viewport.content;

    if (contentValue === "" || contentValue === null) {
      return null;
    }

    const contentValues: Array<string> = contentValue.split(",");

    for (let i = 0; i < contentValues.length; ++i) {
      let val = contentValues[i];

      while (val.indexOf(" ") >= 0) {
        val = val.replace(" ", "");
      }

      const parts: Array<string> = val.split("=");
      const prop: string = parts[0];
      const value: string = parts[1];

      if (prop === "initial-scale") {
        return Math.round(parseFloat(value) * 1000) / 1000;
      }
    }

    return null;
  }


  public dispose(): void {
    if (this._isDisposed) {
      return;
    }
    this._isDisposed = true;

    window.removeEventListener("resize", this.window_resize, false);
  }

  public resize(): void {
    const parent = this._canvas.parentNode;

    if (parent === null || typeof (parent) === "undefined") {
      return;
    }

    const displayBackup = this._canvas.style.display;
    this._canvas.style.display = "none";

    let w: number = (parent as any).clientWidth;
    let h: number = (parent as any).clientHeight;

    let scale = 1;
    if (this._highDefinition) {
      const currentScale: number = CanvasResizer.readViewportScale();
      if (currentScale != null) {
        const pixelRatio: number = (window.devicePixelRatio ? window.devicePixelRatio : 1);
        if (pixelRatio !== 1) {
          scale = pixelRatio * currentScale;
        }
      }
    }
    this._contentScaleFactor = scale;
    w *= scale;
    h *= scale;

    // Check to see if a resize is even necessary
    let resizeNeeded = true;
    if (this._width === w && this._height === h) {
      resizeNeeded = false;
    }

    // Set the new canvas size
    if (resizeNeeded) {
      this._canvas.width = w; // * scale;
      this._canvas.height = h; // * scale;
      this._width = w;
      this._height = h;

      // Reset and scale the rendering transform
      const ctx = this._canvas.getContext("2d");
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(this._contentScaleFactor, this._contentScaleFactor);
    }

    // Restore the canvas display property
    this._canvas.style.display = displayBackup;

    // dispatch the resize event if we actually resized
    if (resizeNeeded) {
      this._canvas.dispatchEvent(new Event("resize"));
    }
  }

  // }

  // { Event Handlers
  /**
   * Handles window resize events.
   */
  private window_resize(evt: UIEvent): void {
    // make the canvas respond in size
    if (this._autoResize) {
      this.resize();
    }
  }

  // }

}
