<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>Add New</span>
      <div class="spacer"></div>
      <mat-icon (click)="close()">close</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="body">
    <div class="elements">
      <p aeMarkAsterisk class="label">Add For</p>
      <mat-radio-group [formControl]="controls.addNew" class="check-box tw-flex tw-flex-col tw-space-y-3"
        color="primary">
        <mat-radio-button value="qa">
          QA
        </mat-radio-button>
        <mat-radio-button value="energy">
          Energy
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="buttons">
      <button (click)="close()" color="warn" mat-flat-button>Cancel</button>
      <div class="spacer"></div>
      <button (click)="submit()" *ngIf="!isLoading" color="primary" mat-flat-button type="submit">Add</button>
      <mat-spinner *ngIf="isLoading" [diameter]="30" strokeWidth="2"></mat-spinner>

    </div>
  </div>
</div>