export enum ToolsPath {
  file_uploads = "file-uploads",
  email_distribution = "email-distribution",
  weekly_alerts = "weekly-alerts",
  scheduled_alerts = "scheduled-alerts",
  site_maps = "site-maps",
  templates = "templates",
  safety_inspection_template = "safety-inspection-templates",
  qa_inspection_template = "qa-inspection-templates",
  mailing_lists = "mailing-lists",
  roles = "roles",
  markets = "markets",
  template_categories = "template-categories",
  options = "options",
  states = "states"
}

export const ToolsTitle = {
  [ToolsPath.email_distribution]: "Email Distribution",
  [ToolsPath.templates]: "Templates",
  [ToolsPath.mailing_lists]: "Mailing Lists",
  [ToolsPath.roles]: "User Roles",
  [ToolsPath.markets]: "Markets",
  [ToolsPath.template_categories]: "Template Categories",
  [ToolsPath.options]: "Options",
  [ToolsPath.weekly_alerts]: "Weekly Alerts",
  [ToolsPath.scheduled_alerts]: "Scheduled Alerts",
  [ToolsPath.file_uploads]: "File Uploads",
  [ToolsPath.site_maps]: "Site Maps",
  [ToolsPath.states]: "States",
  [ToolsPath.safety_inspection_template]: "Safety Inspection Templates",
  [ToolsPath.qa_inspection_template]: "Qa Inspection Templates",
  
};
