<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="popup-title">{{title}}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content">
    <p>{{description}}</p>
    <div class="force-delete" *ngIf="showForceDelete">
      <p>Force Delete</p>
      <mat-checkbox color="primary" [formControl]="forceDeleteValue"></mat-checkbox>
    </div>
    <span class="spacer"></span>
    <div class="buttons">
      <button (click)="clickYes()" *ngIf="showYesButton" color="primary" mat-flat-button>{{yesButtonTitle}}</button>
      <button (click)="clickNo()" *ngIf="showNoButton" color="warn" mat-flat-button>{{noButtonTitle}}</button>
    </div>
  </div>
</div>
