<div class="pin-dialogue-container">
  <div class="heading">
    <h2 style="width: 100%">{{data && data.isViewOnly ? 'View' : 'Edit'}} Pin Data</h2>
    <div (click)="closePopup()" style="cursor: pointer">
      <i class="material-icons">
        close
      </i>
    </div>
  </div>
  <form ngForm (ngSubmit)="submit()" class="pin-details-form">
    <mat-form-field floatLabel="never" matTooltip="Title of pins cannot be changed.">
      <mat-label>Responsive Action</mat-label>
      <input matInput placeholder="Pin Title" [formControl]="titleControl" type="text">
      <mat-error *ngIf="titleControl.invalid">Title is required filed</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="never">
      <mat-label>Location</mat-label>
      <input matInput placeholder="Pin Comment" [formControl]="commentControl" type="text">
      <mat-error *ngIf="commentControl.invalid">Comment is required filed</mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" *ngIf="!data.isViewOnly"
            [disabled]="((titleControl.touched && !titleControl.valid) || (commentControl.touched && !commentControl.valid))"
            type="submit">Save Pin Details
    </button>
  </form>
</div>
