import {AfterViewInit, Directive, ElementRef} from "@angular/core";
import {fromEvent} from "rxjs";
import {distinctUntilChanged, filter, map, pairwise, share, throttleTime} from "rxjs/operators";
import {Direction, VisibilityState} from "@emc-utils/constants";
import {ScrollService} from "@emc-modules/core/services/scroll.service";
import {BreakpointObserver} from "@angular/cdk/layout";

@Directive({
  selector: "[aeScrollListener]"
})
export class ScrollListenerDirective implements AfterViewInit {

  constructor(private elm: ElementRef,
              private breakpointObserver: BreakpointObserver,
              private scrollService: ScrollService) {
  }

  isMobile = true;

  private  userScrolledDown(positions) {
    return positions.sH <= positions.sT + positions.cH + 100;
  }

  ngAfterViewInit() {

    this.breakpointObserver.observe(["(min-width: 959.99px)"]).subscribe(res => {
      this.isMobile = !res.matches;
    });

    const scroll$ = fromEvent(this.elm.nativeElement, "scroll").pipe(
      throttleTime(10),
      map((e: any) => e.target.scrollTop),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      share(),
      distinctUntilChanged(),
      filter(() => this.isMobile)
    );

    const reachedDownward$ = fromEvent(this.elm.nativeElement, "scroll")
      .pipe(map((e: any): ScrollPosition => ({
        sH: e.target.scrollHeight,
        sT: e.target.scrollTop,
        cH: e.target.clientHeight
      })),
      filter(positions => {
        return this.userScrolledDown(positions);
      }));

    const goingUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    goingUp$.subscribe(() => {
      this.scrollService.setScrollVisibilityState(VisibilityState.Visible);
    });

    goingDown$.subscribe(() => {
      this.scrollService.setScrollVisibilityState(VisibilityState.Hidden);
    });

    reachedDownward$.subscribe(() => {
      this.scrollService.setScrollVisibilityState(VisibilityState.Visible);
    });
  }
}

interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
}
