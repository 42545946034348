import { OfflineInspection } from "@emc-models/entities/offline-inspection.model";

export class ModelMappers {

  static mapInspection(data: any): OfflineInspection {
    return {
      id: data.id,
      type: data.type,
      data: data.data,
      date: data.date,
    };
  }

}
