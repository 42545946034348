import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { CommonUtils } from "@emc-utils/common-utils";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { MailingListsQuery } from "@emc-state/tools/mailing-lists/mailing-lists.query";
import { MailingListsApiService } from "@emc-modules/tools/modules/mailing-lists/services/mailing-lists-api.service";
import { MailingListsStore } from "@emc-state/tools/mailing-lists/mailing-lists.store";
import { MailingListCompact, MailingListFull } from "@emc-models/entities/mailing-list.model";
import * as _ from "lodash";
import { ExportFormat } from "@emc-utils/constants";
import { ApiService } from "@emc-modules/core/services/api.service";
import { saveAs } from "file-saver/dist/FileSaver";
import {OptionsStore} from "@emc-state/option/options.store";


@Injectable({ providedIn: "root" })
export class MailingListsService {

  constructor(private query: MailingListsQuery,
              private apiService: ApiService,
              private mailingListsApiService: MailingListsApiService,
              private store: MailingListsStore,
              private  optionsStore: OptionsStore ) {
  }

  getIsLoading(): Observable<boolean> {
    return this.query.$selectIsLoading;
  }

  listMailingLists(force = false): Observable<MailingListCompact[]> {
    let _loading = false;
    let _loaded = false;

    this.getIsLoading()
      .pipe(take(1))
      .subscribe(v => _loading = v);

    this.query.$selectIsLoaded
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    if (force || (!_loaded && !_loading)) {
      this.store.update({
        isLoading: true,
        isLoaded: false
      });
      this.mailingListsApiService.listMailingLists()
        .subscribe(mailingLists => {
          const ids = mailingLists.map(m => m.id);
          const entities = CommonUtils.normalize(mailingLists);
          this.store.update(state => {
            state.fullLoadedIds.forEach(id => {
              delete entities[id];
            });
            return {
              isLoaded: true,
              isLoading: false,
              ids,
              entities: {
                ...state.entities,
                ...entities
              }
            };
          });
        });
    }
    return this.query.getMailingLists();
  }

  getMailingListById(mailingListId: number): Observable<MailingListFull> {
    let _loading = false;
    let _loaded = false;

    this.query.$selectIsFullLoading(mailingListId)
      .pipe(take(1))
      .subscribe(v => _loading = v);

    this.query.$selectIsFullLoaded(mailingListId)
      .pipe(take(1))
      .subscribe(v => _loaded = v);

    if (!_loading && !_loaded) {
      this.store.setFullLoading(mailingListId);
      this.mailingListsApiService.showMailingList(mailingListId)
        .subscribe((mailingList: MailingListFull) => {
          this.store.update(state => {
            let ids = [...state.ids, mailingListId];
            ids = _.uniq(ids);
            return {
              ids,
              entities: {
                ...state.entities,
                [mailingListId]: mailingList
              }
            };
          });
          this.store.setFullLoaded(mailingListId);
        });
    }
    return this.query.selectEntity(mailingListId)
      .pipe(filter((mailingList: MailingListFull) => !!mailingList && mailingList.__type === "full"));
  }

  addMailingLists(data: any): Observable<MailingListFull> {
    return this
      .mailingListsApiService
      .addMailingList(data)
      .pipe(tap((mailingList) => {
        this.optionsStore.createMailingList(mailingList);
        let ids = [];
        let entities = {};
        this.query.$selectIds.pipe(take(1)).subscribe(v => ids = v);
        this.query.$selectEntities.pipe(take(1)).subscribe(v => entities = v);
        this.store.update({
          ids: [
            ...ids,
            mailingList.id
          ],
          entities: {
            ...entities,
            [mailingList.id]: mailingList
          }
        });
      }));
  }

  deleteMailingList(mailingListId: ID) {
    return this
      .mailingListsApiService
      .deleteMailingList(mailingListId)
      .pipe(tap(() => {
        this.optionsStore.deleteMailingList(mailingListId);
        let ids = [];
        let entities = {};
        this.query.$selectIds.pipe(take(1)).subscribe(v => ids = [...v]);
        this.query.$selectEntities.pipe(take(1)).subscribe(v => entities = { ...v });
        ids = ids.filter(i => i !== mailingListId);
        delete entities[mailingListId];
        this.store.update({
          ids: [
            ...ids,
          ],
          entities: {
            ...entities,
          }
        });
      }));
  }

  updateMailingList(mailingListId: ID, data: any): Observable<MailingListFull> {
    return this
      .mailingListsApiService
      .updateMailingList(mailingListId, data)
      .pipe(tap((mailingList) => {
        this.optionsStore.updateMailingList(mailingListId, mailingList);
        let entities = {};
        this.query.$selectEntities.pipe(take(1)).subscribe(v => entities = v);
        this.store.update({
          entities: {
            ...entities,
            [mailingList.id]: mailingList
          }
        });
      }));
  }

  exportMailingLists(format: ExportFormat, ids: ID[], allSelected: boolean): Observable<void> {
    let data: any = {
      format
    };

    if (allSelected) {
      data = {
        ...data,
        export_all: allSelected
      };
    } else {
      data = {
        ...data,
        mailing_list_ids: ids
      };
    }

    return this.apiService.export("export/mailing-lists", data);
  }

}
