<div class="container">
  <mat-toolbar class="header" color="primary">
    <span>Register Your Signature</span>
  </mat-toolbar>
  <form [formGroup]="form" class="edit-form">
    <div class="body-elements">
      <ae-signature [formControl]="controls.signature"></ae-signature>
    </div>
    <div class="action-container">
      <mat-spinner *ngIf="saving" [diameter]="20" strokeWidth="2"></mat-spinner>
      <button (click)="submit()" [disabled]="saving" class="primary" mat-button>Save</button>
    </div>
  </form>
</div>
