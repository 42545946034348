<div class="section-body__info">
  <div *ngIf="title" class="section-body__info_title">
    <span>{{title}}</span>
    <span class="space-filler"></span>
    <ng-container *ngIf="showButton">
      <button (click)="onClick()" color="primary" mat-flat-button type="button">{{buttonText}}</button>
    </ng-container>
  </div>
  <div class="emc-box">
    <ng-content></ng-content>
  </div>
</div>