import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "@emc-modules/material/material.module";
import { BackButtonComponent } from "@emc-modules/shared/components/back-button/back-button.component";
import { EmcMainContainerComponent } from "@emc-modules/shared/components/emc-main-conatiner/emc-main-container.component";
import { EmcMainContainerBodyDirective } from "@emc-modules/shared/directives/emc-main-container-body.directive";
import { EmcMainContainerSideNavDirective } from "@emc-modules/shared/directives/emc-main-container-side-nav.directive";
import { LoadMoreScrollDirective } from "@emc-modules/shared/directives/load-more-scroll.directive";
import { UnSlugifyPipe } from "@emc-modules/shared/pipes/unslugify.pipe";
import { SectionContainerComponent } from "./components/section-container/section-container.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TruncatePipe } from "@emc-modules/shared/pipes/truncate.pipe";
import { FlexAlignmentHackDirective } from "@emc-modules/shared/directives/flex-alignment-hack.directive";
import { DialogService } from "@emc-modules/shared/services/dialog.service";
import { EmcSearchFieldComponent } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { GenericConfirmationComponent } from "./components/generic-confirmation/generic-confirmation.component";
import { BreadcrumbComponent } from "@emc-modules/shared/components/breadcrumb/breadcrumb.component";
import { EditDialogComponent } from "@emc-modules/shared/dialog/edit-dialog/edit-dialog.component";
import { ScrollListenerDirective } from "./directives/scroll-listener.directive";
import { EmcSearchBoxComponent } from "@emc-modules/shared/components/emc-search-box/emc-search-box.component";
import { AcronymPipe } from "@emc-modules/shared/pipes/acronym.pipe";
import { AEFilterPipe } from "./pipes/filter.pipe";
import { SlugifyPipe } from "@emc-modules/shared/pipes/slugify.pipe";
import { SignatureComponent } from "./components/signature/signature.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SignatureRegisterComponent } from "./components/signature-register/signature-register.component";
import { SafePipe } from "@emc-modules/shared/pipes/safe.pipe";
import { NgxFileDropModule } from "ngx-file-drop";
import { TagInputModule } from "ngx-chips";
import { ViewTemplateComponent } from "@emc-modules/tools/modules/templates/components/templates/view-template/view-template.component";
import { AddEditTemplateComponent } from "@emc-modules/tools/modules/templates/components/templates/add-edit-template/add-edit-template.component";
import { QuestionSuggestionComponent } from "@emc-modules/tools/modules/templates/components/templates/add-edit-template/components/question-suggestion/question-suggestion.component";
import { ResponseActionComponent } from "@emc-modules/tools/modules/templates/components/templates/add-edit-template/components/response-action/response-action.component";
import { SubQuestionCardComponent } from "@emc-modules/tools/modules/templates/components/sub-question-card/sub-question-card.component";
import { ViewContainerComponent } from "@emc-modules/shared/components/view-container/view-container.component";
import { AddEditContainerComponent } from "@emc-modules/shared/components/add-edit-container/add-edit-container.component";
import { MailingListContainerComponent } from "@emc-modules/shared/components/mailing-list-container/mailing-list-container.component";
import { ViewMailingListComponent } from "@emc-modules/tools/modules/mailing-lists/components/view-mailing-list/view-mailing-list.component";
import { ViewResponsiveActionItemCardComponent } from "@emc-modules/inspections/components/view-responsive-action-item-card/view-responsive-action-item-card.component";
import { AddEditActionItemComponent } from "@emc-modules/inspections/components/add-edit-action-item/add-edit-action-item.component";
import { AddEditNoteComponent } from "@emc-modules/inspections/components/add-edit-note/add-edit-note.component";
import { BulkUpdateActionsComponent } from "@emc-modules/inspections/components/bulk-update-actions/bulk-update-actions.component";
import { InspectionCardComponent } from "@emc-modules/inspections/components/inspection-card/inspection-card.component";
import { AdminReportComponent } from "@emc-modules/request-reports/modules/admin-upload-reports/components/admin-report/admin-report.component";
import { MapEngineComponent } from "./components/map-engine/map-engine.component";
import { MapPinComponent } from "@emc-modules/shared/components/map-pin/map-pin.component";
import { MapDialogueComponent } from "./components/map-dialogue/map-dialogue.component";
import { DragAndDropModule } from "angular-draggable-droppable";
import { FullScreenMapComponent } from "@emc-modules/inspections/components/dialogues/full-screen-map/full-screen-map.component";
import { SatPopoverModule } from "@ncstate/sat-popover";
import { ResAcItemCardDialogComponent } from "@emc-modules/projects/components/responsive-action-item-card-dialog-box/res-ac-item-card-dialog.component";
import { ImageDirective } from "@emc-modules/shared/directives/image.directive";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import {EmailTextAreaComponent} from "@emc-modules/shared/components/email-text-area/email-text-area.component";
import { EmcAutocompleteComponent } from "@emc-modules/shared/components/emc-autocomplete/emc-autocomplete.component";
import {LongPressDirective} from "@emc-modules/shared/directives/long-press.directive";
import { TouchClickDirective } from "@emc-modules/shared/directives/touch-click.directive";
import {MarkAsteriskDirective} from "@emc-modules/shared/directives/mark-asterisk.directive";
import { OptionCardComponent } from "@emc-modules/tools/modules/templates/components/option-card/option-card.component";
import { GenericSaveConfirmationComponent } from "@emc-modules/shared/components/generic-save-confirmation/generic-save-confirmation.component";
import { InputDirective } from "@emc-modules/shared/directives/input.directive";
import { SortByPipe } from "@emc-modules/shared/pipes/sort.pipe";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { EmcInputFieldComponent } from "./components/emc-input-field/emc-input-field.component";
import { TitleCaseExceptPipe } from "./pipes/titleCaseExcept.pipe";


const COMPONENTS = [
  EmcMainContainerComponent,
  SectionContainerComponent,
  BackButtonComponent,
  EmcSearchFieldComponent,
  EmcAutocompleteComponent,
  GenericConfirmationComponent,
  GenericSaveConfirmationComponent,
  BreadcrumbComponent,
  EmcSearchBoxComponent,
  SignatureComponent,
  ViewMailingListComponent,
  AddEditTemplateComponent,
  QuestionSuggestionComponent,
  ResponseActionComponent,
  SubQuestionCardComponent,
  ViewContainerComponent,
  AddEditContainerComponent,
  MailingListContainerComponent,
  InspectionCardComponent,
  AdminReportComponent,
  ViewResponsiveActionItemCardComponent,
  MapEngineComponent,
  EmailTextAreaComponent,
  MapPinComponent,
  OptionCardComponent,
  EmcInputFieldComponent
];
const DIRECTIVES = [
  LoadMoreScrollDirective,
  EmcMainContainerBodyDirective,
  EmcMainContainerSideNavDirective,
  FlexAlignmentHackDirective,
  ScrollListenerDirective,
  ImageDirective,
  LongPressDirective,
  TouchClickDirective,
  MarkAsteriskDirective,
  InputDirective,
];
const PIPES = [
  UnSlugifyPipe,
  TruncatePipe,
  AcronymPipe,
  AEFilterPipe,
  SlugifyPipe,
  SafePipe,
  SortByPipe,
  TitleCaseExceptPipe,
];

const MODULES = [
  CommonModule,
  MaterialModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  NgxSliderModule,
  NgxMaterialTimepickerModule,
  NgxFileDropModule,
  TagInputModule,
  DragAndDropModule,
  SatPopoverModule,
  CKEditorModule,
  VirtualScrollerModule
];

const SERVICES = [DialogService];

const DIALOGS = [EditDialogComponent,
  SignatureRegisterComponent,
  AddEditActionItemComponent,
  AddEditNoteComponent,
  ViewTemplateComponent,
  BulkUpdateActionsComponent,
  FullScreenMapComponent,
  ResAcItemCardDialogComponent,
  MapDialogueComponent];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...DIALOGS,
    ...PIPES,
    ScrollListenerDirective,
    MapDialogueComponent],
  imports: [
    MODULES,
  ],
  providers: SERVICES,
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...MODULES
  ],
})
export class SharedModule {
}
