<mat-form-field class="field" floatLabel="never">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select (openedChange)="$event?searchField.focus():null" (selectionChange)="onSelectionChanged($event.value)" [disabled]="disabled" [formControl]="control"
              [multiple]="multiple"
              [placeholder]="placeholder" class="erp-mat-select">
    <mat-form-field class="option" color="primary" floatLabel="auto">
      <mat-label>Search</mat-label>
      <input (keydown.Space)="$event.stopPropagation()" [formControl]="searchControl" class="search-field" matInput #searchField
             placeholder="Search">
    </mat-form-field>
    <div style="overflow-y: scroll;max-height: 350px;">
      <ng-container *ngIf="multiple">
        <button (click)="selectAll()" class="option" mat-button>Select All</button>
        <button (click)="selectNone()" class="option" mat-button>Select None</button>
      </ng-container>
      <mat-option (onLongPress)="onLongPress(option.title)" *ngFor="let option of options; trackBy: trackByValue"
                  [class.hide]="!isVisible(option.value)"
                  [matTooltip]="option.title" [value]="option.value" aeLongPress>
        {{option.title}}
      </mat-option>
    </div>
  </mat-select>
  <mat-error>This field is required</mat-error>
  <mat-error *ngIf="control.hasError('validation')">Seems like this field is invalid</mat-error>
  <mat-icon (click)="$event.stopPropagation();reset()" *ngIf="!!control.value && !multiple"
            color="warn" matSuffix matTooltip="Reset Selection">
    close
  </mat-icon>
</mat-form-field>
