<div [ngClass]="{'certified': !!inspection.sswr_certified_on}" class="emc-box">
  <div class="header">
    <div class="name">
      <mat-checkbox (change)="onChange($event)"
                    *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_EXPORT) && showButtons"
                    [(ngModel)]="selected" [disabled]="disabled" color="primary">
      </mat-checkbox>
      <h4>{{inspection.inspection_date | date}}</h4>
    </div>
    <div class="action-buttons">
      <mat-icon
        (click)="onClick(inspection, 'view')"
        *ngIf="PermissionUtils.hasAnyPermission([PermissionType.INSPECTION_VIEW, PermissionType.INSPECTION_VIEW_ASSIGNED])"
        color="primary"
        matTooltip="View">visibility
      </mat-icon>
      <ng-container
        *ngIf="!inspection.is_finalized || PermissionUtils.hasPermission(PermissionType.INSPECTION_MODIFY_AFTER_FINALIZE)">
        <ng-container *ngIf="!deleting">
          <mat-icon (click)="onDelete()"
                    *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_DELETE) ||
                  (PermissionUtils.hasPermission(PermissionType.INSPECTION_DELETE_ASSIGNED))"
                    color="warn" matTooltip="Delete">delete
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="deleting">
          <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        </ng-container>
        <mat-icon (click)="onClick(inspection, 'edit')"
                  *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT) ||
                  (PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT_ASSIGNED))"
                  color="primary"
                  matTooltip="Edit">edit
        </mat-icon>
      </ng-container>
      <mat-icon *ngIf="showButtons && childs>0" [matMenuTriggerFor]="menu" class="menu" color="primary">more_vert</mat-icon>
      <mat-menu  #menu yPosition="below">
        <button (click)="addRainLog()" *ngIf="isStormWater" mat-menu-item>Add Rain Log</button>

        <button (click)="openResponsiveActionItems()" *ngIf="isStormWater" mat-menu-item>Open Items</button>
        <ng-container
          *ngIf="isStormWater && canCertify">
          <button (click)="onClick(inspection, 'certify')" [disabled]="!!inspection.sswr_certified_on" mat-menu-item>
            {{!inspection.sswr_certified_on ? "Certify" : "Certified"}}
          </button>
        </ng-container>
        <ng-container *ngIf="!mapsLoading">
        <button
          (click)="openMaps()"
          *ngIf="isStormWater && PermissionUtils.hasPermission(PermissionType.TOOL_SITE_MAP_VIEW)"
          mat-menu-item>
          <span>Maps</span>
        </button>
        </ng-container>
        <ng-container *ngIf="mapsLoading">
          <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        </ng-container>
        <button (click)="addNew(inspection)"
          *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CREATE) && (inspection.type === 'qa' || inspection.type === 'energy' )"
          mat-menu-item>Add New</button>
        <ng-container *ngIf="inspection.is_finalized">
          <button (click)="remail()" *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CAN_REMAIL)" mat-menu-item>
            <span>Re-Email</span>
          </button>
          <ng-container *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT) ||
                        (PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT_ASSIGNED))">
            <button (click)="followup()"
                    *ngIf="inspection.type === 'qa' && !inspection?.qaInspection?.has_followed_up && inspection?.qaInspection.follow_up_reference_number < 2 && (inspection?.qaInspection?.serviceType?.title==='QA1' || inspection?.qaInspection?.serviceType===null)"
                    mat-menu-item>
              <span>Follow Up</span>
            </button>
          </ng-container>
          <button *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_PRINT_PDF)" (click)="export('pdf')" mat-menu-item>
            <span>Print to PDF</span>
          </button>
          <button (click)="openRecepients()" *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CAN_VIEW_RECEPIENTS)" mat-menu-item>
            <span>E-Recipients</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
  <div class="body">
    <div class="item">
      <h3 class="title">Client</h3>
      <p class="content" matTooltip="{{inspection?.client?.name}}">{{inspection?.client?.name}}</p>
    </div>
    <div class="item">
      <h3 class="title">Project</h3>
      <p class="content" matTooltip="{{inspection?.project?.title}}">{{inspection?.project?.title || "-"}}</p>
    </div>
    <ng-container *ngIf="inspection.type === 'qa'">
      <div class="item">
        <h3 class="title">Type</h3>
        <p class="content">{{inspection?.qaInspection?.follow_up_reference_number === 0 ? 'Punch List' : (inspection?.qaInspection?.follow_up_reference_number===2?'QA2': inspection?.qaInspection.serviceType?.title || 'QA1'  )}}</p>
      </div>
    </ng-container>
    <div class="item">
      <h3 class="title">CFC</h3>
      <p class="content"
         matTooltip="{{(inspection?.inspector?.first_name + ' ' + inspection?.inspector?.last_name)}}">{{inspection.inspector ? (inspection.inspector.first_name + " " + inspection.inspector.last_name) : "-"}}</p>
    </div>
    <ng-container *ngIf="inspection.type === 'energy'">
      <div class="item">
        <h3 class="title">Lot#</h3>
        <p class="content">{{inspection?.energyInspection.lot_number}}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="inspection.type === 'energy'">
      <div class="item">
        <h3 class="title">Type</h3>
        <p class="content">{{inspection?.energyInspection.type | unslugify | titlecase}}</p>
      </div>
      <div class="item">
        <h3 class="title">Street Address</h3>
        <p class="content">{{inspection?.energyInspection.address }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="inspection.type === 'qa'">
      <div class="item">
        <h3 class="title">Home Site</h3>
        <p class="content">{{inspection?.qaInspection.home_site}}</p>
      </div>
      <div class="item">
        <h3 class="title">Street Address</h3>
        <p class="content">{{inspection?.qaInspection.street_address || '-'}}</p>
      </div>
    </ng-container>
    <span class="space-filler"></span>
  </div>
  <div class="visible">
  <ng-container *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_FINALIZE) ||
                  (PermissionUtils.hasPermission(PermissionType.INSPECTION_FINALIZE_ASSIGNED))">
    <button (click)="onClick(inspection, 'finalize')" *ngIf="!inspection.is_finalized" class="primary"
            mat-flat-button>
      Finalize
    </button>
  </ng-container>
  </div>
  <div *ngIf="showButtons" class="actions">

    <ng-container *ngIf="isStormWater">
      <button (click)="addRainLog()" class="secondary" mat-flat-button>Add Rain Log</button>

      <button (click)="openResponsiveActionItems()" class="secondary" mat-flat-button>Open Items</button>
      <button (click)="onClick(inspection, 'certify')"
              *ngIf="canCertify"
              [disabled]="!!inspection.sswr_certified_on" class="secondary" mat-flat-button>
        {{!inspection.sswr_certified_on ? "Certify" : "CERTIFIED"}}
      </button>
    </ng-container>


    <ng-container *ngIf="!markersLoading && !siteMapsLoading">
      <button
        (click)="openMaps()"
        *ngIf="isStormWater && PermissionUtils.hasPermission(PermissionType.TOOL_SITE_MAP_VIEW)"
        class="secondary" mat-flat-button>Maps
      </button>
    </ng-container>

    <ng-container *ngIf="markersLoading || siteMapsLoading">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </ng-container>
    <button (click)="addNew(inspection)"
      *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CREATE) && (inspection.type === 'qa' || inspection.type === 'energy' ) && !saving"
      class="secondary" mat-flat-button>Add New</button>

    <ng-container *ngIf="inspection.is_finalized && !saving">
      <ng-container *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT) ||
                        (PermissionUtils.hasPermission(PermissionType.INSPECTION_EDIT_ASSIGNED))">
        <button (click)="followup()"
                *ngIf="inspection.type === 'qa' && !inspection?.qaInspection?.has_followed_up && inspection?.qaInspection.follow_up_reference_number < 2"
                class="secondary" mat-flat-button>Follow Up
        </button>
      </ng-container>
      <button (click)="remail()" *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CAN_REMAIL)" class="secondary" mat-flat-button>Re-email</button>
      <button *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_PRINT_PDF)" (click)="export('pdf')" class="secondary" mat-flat-button>Print to PDF</button>
      <button *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_CAN_VIEW_RECEPIENTS)" (click)="openRecepients()" class="secondary" mat-flat-button>E-Recipients
      </button>
    </ng-container>

    <ng-container *ngIf="saving">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="PermissionUtils.hasPermission(PermissionType.INSPECTION_FINALIZE) ||
                  (PermissionUtils.hasPermission(PermissionType.INSPECTION_FINALIZE_ASSIGNED))">
      <button (click)="onClick(inspection, 'finalize')" *ngIf="!inspection.is_finalized" class="primary"
              mat-flat-button>
        Finalize
      </button>
    </ng-container>
  </div>
</div>
