import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UserFull} from "@emc-models/entities/user.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AuthManager} from "@emc-modules/core/managers/auth.manager";

import {takeUntil} from "rxjs/operators";
import {Unsubscribe} from "../../../../../../unsubscribe";
import {MailingListsService} from "@emc-modules/tools/modules/mailing-lists/services/mailing-lists.service";
import {MailingListsQuery} from "@emc-state/tools/mailing-lists/mailing-lists.query";
import {MailingListFull} from "@emc-models/entities/mailing-list.model";
import { IBreadcrumb } from "@emc-modules/shared/interfaces/breadcrumb.interface";
import { AppPath, AppTitle } from "../../../../../../app.path";
import { ToolsPath, ToolsTitle } from "@emc-modules/tools/tools.path";
import { MailingListsPath, MailingListsTitle } from "@emc-modules/tools/modules/mailing-lists/mailing-lists.path";
import { footerAnimation, VisibilityState } from "@emc-utils/constants";
import { ScrollService } from "@emc-modules/core/services/scroll.service";
import {PermissionUtils} from "@emc-utils/permission.utils";
import {PermissionType} from "../../../../../../enums/permission-type.enum";

@Component({
  selector: "ae-view-mailing-list",
  templateUrl: "./view-mailing-list.component.html",
  styleUrls: ["./view-mailing-list.component.scss"],
  animations: footerAnimation
})
export class ViewMailingListComponent extends Unsubscribe implements OnInit {
  breadcrumbs: IBreadcrumb[] = [
    {
      url: AppPath.dashboard,
      label: AppTitle[AppPath.dashboard]
    },
    {
      url: AppPath.tools,
      label: AppTitle[AppPath.tools]
    },
    {
      url: ToolsPath.mailing_lists,
      label: ToolsTitle[ToolsPath.mailing_lists]
    },
    {
      url: MailingListsPath.view,
      label: MailingListsTitle[MailingListsPath.view]
    }
  ];

  isMailingListLoading: boolean;
  mailingList: MailingListFull;
  authUser: UserFull;
  state: VisibilityState = VisibilityState.Visible;
  VisibilityState = VisibilityState;
  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  @Input() isDialog = false;
  @Input() mailingListId: number;
  @Output() closeDialog = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authManager: AuthManager,
    private scrollService: ScrollService,
    private mailingListsService: MailingListsService,
    private mailingListsQuery: MailingListsQuery) {
    super();
  }

  ngOnInit() {
    if (!this.mailingListId) {
      this.mailingListId = +this.route.snapshot.paramMap.get("id");
    }
    this.mailingListsQuery.$selectIsFullLoading(this.mailingListId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => this.isMailingListLoading = l);

    this.mailingListsService.getMailingListById(this.mailingListId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mailingList => {
        this.mailingList = mailingList;
      });

    this.authManager.getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(user => this.authUser = user);

    this.scrollService.getScrollVisibilityState()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(state => {
        this.state = state;
      });

  }


  edit() {
    console.log("in edit");
    this.router.navigate(["dashboard/tools/mailing-lists", this.mailingList.id, "edit"]).then(r => {
    });
  }


  goBack(): void {
    this.location.back();
  }

  close() {
    this.closeDialog.emit();
  }
}
